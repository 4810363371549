import React, { useEffect, useState } from "react";
import CourseService from "../../services/CourseService";
import BatcheManagementService from "../../services/BatchManagementServices";
import formloader from "../../images/formloading.svg";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import { FaPen } from "react-icons/fa";
import $ from "jquery";
import { RiDeleteBin3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
const { getUserName } = require("../../utils/user/getUserName");
const { format } = require("../../../src/utils/Date/format");

export default function CreateExamSchedule1(props) {
  var token = localStorage.getItem("token");
  const user = jwt_decode(token);
  const navigate = useNavigate();
  const handlecreatedAndUpdateby = (createdupdatedby) => {
    //global file for returning name from object(id, username)
    return getUserName(createdupdatedby);
  };
  const handleCreateUpadateDate = (date) => {
    return format(date);
  };

  const initialExamSchedule = {
    trainerId: token && user.id,
    skillId: "",
    batchId: "",
    totalQuestion: "",
    totalTime: "",
    examDate: "",
    endDate: "",
    typeOfExam: "",
    sendEmail: "",
    examType: "",
    totalUsers: [],
    markAsCompleted: false,
    easy: 0,
    medium: 0,
    hard: 0,
  };
  const [loading, setLoading] = useState(false);
  const [batchesOptions, setBatchesOptions] = useState([]);
  const [batches, setBatches] = useState([]);
  const [subCourseBatches, setSubCourseBatches] = useState([]);
  const [trainerRequest, setTrainerRequest] = useState([]);
  const [batchUser, setBatchUser] = useState([]);
  const [batchUserData, setBatchUserData] = useState([]);
  const [createExamSchedule, setCreateExamSchedule] =
    useState(initialExamSchedule);
  const [updateData, setUpdateData] = useState("");
  const [createExamId, setCreateExamId] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [totalQuestions, setTotalQuestions] = useState({
    length: 0,
    easy: {},
    medium: {},
    hard: {},
    status: false,
  });
  const [page, setPage] = useState({ selected: 1, choice: "" });
  const [msg, setMsg] = useState({ message: "", status: false });
  const [confirmBox, setConfirmBox] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const typeOf = [
    {
      value: "Internal",
      label: "Internal",
    },
    {
      value: "External",
      label: "External",
    },
  ];
  const [subSkillsOptions, setSubSkillsOptions] = useState([]);
  const [selectedSubSkills, setSelectedSubSkills] = useState([]);
  const [selectedUserSubSkills, setSelectedUserSubSkills] = useState({});
  const [userSkills, setUserSkills] = useState([]);
  const [multiSkill, setMultiSkill] = useState(false);
  const [subTopicQuestion, setSubTopicQuestion] = useState([]);
  const [pickQuestionVaidation, setPickQuestionValidation] = useState([]);
  const [totalTimeVaidation, setTotalTimeValidation] = useState([]);
  const [questionVaidation, setQuestionValidation] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [buttonStatus, setButtonStatus] = useState(false);
  const getAllTrainingBatch = (token) => {
    BatcheManagementService.GetAllBatch(token).then((res) => {
      setBatches(res.data.data);
      const tempBatches = [];
      if (res.data.data.length > 0) {
        for (let i = 0; i < res.data.data.length; i++) {
          const batch = res.data.data[i];
          const temp = {
            value: batch._id,
            label: batch.name,
          };
          tempBatches.push(temp);
        }
      }
      setBatchesOptions([...tempBatches]);
    });
  };

  const getExamSchedule = (pageNumber, status = false) => {
    const role = jwt_decode(token)?.role
    CourseService.getExamScheduleForCreateExam(token, role, pageNumber)
      .then((res) => {
        if (!status) {
          setTrainerRequest([...trainerRequest, ...res.data.examList]);
          setButtonStatus(res.data.buttonStatus)
        } else {
          setTrainerRequest(res.data.examList);
          setButtonStatus(res.data.buttonStatus)
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubCourses = (token) => {
    CourseService.getSubCourses(token)
      .then((res) => {
        const subSkillsOptions = [];
        for (var i = 0; i < res.data.length; i++) {
          const temp = {
            value: res.data[i].name,
            label: res.data[i].name,
            _id: res.data[i]._id,
          };
          subSkillsOptions.push(temp);
        }
        setSubSkillsOptions(subSkillsOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (token) {
      getAllTrainingBatch(token);
      getExamSchedule(pageNumber);
      getSubCourses(token);
    } else {
      navigate("/login");
    }
  }, []);

  const BindModal = (users, examid) => {
    // const users = await BatchManagementServices.getBatchById(data._id, token);
    var app = `
      ${users
        ? `
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">Exam Code</th>
              <th scope="col">Internal</th>
            </tr>
            </thead>
            <tbody id="tableBody">${users.map((item, index) => {
          return `
                <tr>
                  <td>${index + 1}</td>
                  <td>${item && item.username}</td>
                  <td>${btoa(examid + "-" + item.empId)}</td>
                  <td>${atob(btoa(examid + "-" + item.empId))}</td>
                </tr>`;
        })}</tbody>`
        : "No Data Found"
      }`;

    $("#table").html("");
    $("#table").html(app);
  };

  const handleSkill = (id) => {
    setCreateExamSchedule({ ...createExamSchedule, skillId: id });
    setUserSkills([]);
  };
  const handleBatch = (id) => {
    setCreateExamSchedule({ ...createExamSchedule, batchId: id });
    setUserSkills([]);
  };
  const handleType = (id) => {
    setCreateExamSchedule({ ...createExamSchedule, typeOfExam: id });
  };

  const handleSubTopicList = (subTopicLists) => {
    let topicList = {};
    let easyTopicList = {};
    let mediumTopicList = {};
    let hardTopicList = {};
    if (subTopicLists.easy && subTopicLists.medium && subTopicLists.hard) {
      const difficulty = subTopicLists;
      for (let topics in difficulty) {
        if (topics === "easy" || topics === "medium" || topics === "hard") {
          const subTopicArray =
            difficulty[topics].easySubTopics ||
            difficulty[topics].mediumSubTopics ||
            difficulty[topics].hardSubTopics;
          if (subTopicArray?.length > 0) {
            subTopicArray.forEach((element) => {
              var key1 = Object.keys(element)[0];
              topicList[key1] = 0;
            });
          }
          if (topics === "easy") {
            easyTopicList = {
              easy: topicList,
            };
            topicList = {};
          } else if (topics === "medium") {
            mediumTopicList = {
              medium: topicList,
            };
            topicList = {};
          } else {
            hardTopicList = {
              hard: topicList,
            };
            topicList = {};
          }
        }
      }
      return [easyTopicList, mediumTopicList, hardTopicList];
    }
  };

  useEffect(() => {
    if (
      createExamSchedule.examType !== "" &&
      createExamSchedule.skillId !== "" &&
      createExamSchedule.typeOfExam !== ""
    ) {
      if (token) {
        const schedule = {
          skillId: createExamSchedule.skillId,
          typeOfExam: createExamSchedule.typeOfExam.value,
          examType: createExamSchedule.examType,
        };
        CourseService.getExamQuestionLength(schedule, token)
          .then(async (res) => {
            if (res.data) {
              if (res.data === "Not Found Data") {
                setTotalQuestions({
                  length: 0,
                  easy: {},
                  medium: {},
                  hard: {},
                  status: false,
                });
              } else {
                if (updateData === "") {
                  const subTopicList = await handleSubTopicList(res.data);
                  setSubTopicQuestion(subTopicList);
                } else {
                  const subTopicList = JSON.parse(
                    localStorage.getItem("subTopicList")
                  );
                  setSubTopicQuestion(subTopicList);
                }
                if (multiSkill) {
                  setTotalQuestions({
                    length: 0,
                    easy: {},
                    medium: {},
                    hard: {},
                    status: false,
                  });
                } else {
                  setTotalQuestions({
                    length: res.data.length,
                    easy: res.data.easy,
                    medium: res.data.medium,
                    hard: res.data.hard,
                    status: true,
                  });
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        navigate("/login");
      }
    }
    // getTotalQuestions(userSkills);
  }, [
    createExamSchedule.skillId,
    createExamSchedule.typeOfExam,
    createExamSchedule.examType,
  ]);

  const handleFilterSkill = (userList) => {
    let temp = [];
    const userSkills = userList.forEach((i) => {
      const skillExists = temp.find((s) => s.skill === i.skill._id);
      if (skillExists) {
        skillExists.users.push(i.user._id);
      } else {
        temp.push({
          ...i,
          users: [i.user._id],
          skill: i.skill._id,
          skillname: i.skill.name,
          totalQuestions: i.totalQuestions || 0,
          pickQuestions: i.pickQuestions || 0,
          totalTimes: i.totalTimes || 0,
          easy: i.easy,
          medium: i.medium,
          hard: i.hard,
          totalEasy: i.totalEasy,
          totalMedium: i.totalMedium,
          totalHard: i.totalHard,
          subSkillTopicName: i.subSkillTopicName,
        });
      }
      // return null;
    });
    return temp;
  };

  const getTotalQuestions = (usersSkills, editFlag = true) => {
    if (token) {
      const temp = {
        skills:
          editFlag === true
            ? Array.from(usersSkills, (i) => i.skill)
            : usersSkills.subSkills,
        typeOfExam:
          editFlag === true
            ? createExamSchedule.typeOfExam.value
            : usersSkills.typeofExam,
        examType:
          editFlag === true
            ? createExamSchedule.examType
            : usersSkills.examType,
      };
      CourseService.getMultipleSkillExamQuestionLength(temp, token)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.data;
            const temp =
              editFlag === true
                ? usersSkills.filter((item) => {
                  return !userSkills.some((item1) => {
                    return item.skill === item1.skill;
                  });
                })
                : handleFilterSkill(usersSkills.userSkill);

            for (let i = 0; i < temp.length; i++) {
              const userSkill = temp[i];
              let skillQuestions = data.find(
                (i) => i.skill === userSkill.skill
              );
              let getQuestions;
              if (!editFlag) {
                getQuestions = usersSkills.userSkill.find(
                  (i1) => i1.skill._id === userSkill.skill
                );
              }
              userSkill.subSkillTopicName =
                editFlag === true
                  ? handleSubTopicList(skillQuestions)
                  : getQuestions.subSkillTopicName;
              userSkill.totalQuestions = skillQuestions.totalQuestions;
              userSkill.pickQuestions = getQuestions?.pickQuestions || 0;
              userSkill.totalTimes = getQuestions?.totalTimes || 0;
              userSkill.totalEasy = skillQuestions.easy;
              userSkill.totalMedium = skillQuestions.medium;
              userSkill.totalHard = skillQuestions.hard;
              userSkill.easy = getQuestions?.easy || 0;
              userSkill.medium = getQuestions?.medium || 0;
              userSkill.hard = getQuestions?.hard || 0;
            }
            const finalList = userSkills.concat(temp);
            let filterList;
            if (usersSkills?.length > 0) {
              filterList = finalList.filter((item) => {
                return usersSkills.some((item1) => {
                  return item.skill === item1.skill;
                });
              });
            } else {
              filterList = finalList;
            }
            setUserSkills(filterList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (createExamSchedule.batchId) {
      if (token) {
        BatcheManagementService.GetAllBatch(token)
          .then((res) => {
            if (res.data.data) {
              const data = res.data.data.filter(
                (item) => item._id === createExamSchedule.batchId.value
              );
              let tempSkills = [];
              if (data[0].skills.length > 0) {
                for (let i = 0; i < data[0].skills.length; i++) {
                  const batch = data[0].skills[i];
                  const temp = {
                    value: batch.skillId._id,
                    label: batch.skillId.name,
                  };
                  tempSkills.push(temp);
                }
              }
              setSubCourseBatches([...tempSkills]);
              setBatchUser(data);
              if (data[0].users && data[0].users.length > 0) {
                setBatchUserData(data[0].users);
                let users = [];
                const userIdAdd = data[0].users.filter((item) => {
                  if (item.deactivate === false) users.push(item._id);
                });
                if (updateData === "") {
                  setCreateExamSchedule({
                    ...createExamSchedule,
                    totalUsers: users,
                  });
                  setSelectAll(true);
                } else {
                  if (
                    batchUserData.length ===
                    createExamSchedule.totalUsers.length
                  ) {
                    setCreateExamSchedule({
                      ...createExamSchedule,
                      totalUsers: users,
                    });
                    setSelectAll(true);
                  } else {
                    setSelectAll(false);
                  }
                }
              } else {
                setBatchUserData([]);
                setSelectAll(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        navigate("/login");
      }
    }
  }, [createExamSchedule.batchId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      navigate("/login");
    } else {
      if (
        createExamSchedule.skillId !== "" &&
        createExamSchedule.batchId !== "" &&
        (multiSkill
          ? true
          : ((!isNaN(createExamSchedule.totalQuestion) &&
            createExamSchedule.examType === "MCQ" &&
            createExamSchedule.totalTime !== "" &&
            !isNaN(createExamSchedule.totalTime)) ||
            ["Practical", "Meeting"].includes(createExamSchedule.examType)
          ) &&
          (["MCQ", "Practical"].includes(createExamSchedule.examType) ?
          totalQuestions.status === true &&
          parseInt(createExamSchedule.totalQuestion) <=
          totalQuestions.length : true)) &&
        createExamSchedule.endDate !== "" &&
        createExamSchedule.sendEmail !== "" &&
        createExamSchedule.examType !== "" &&
        createExamSchedule.typeOfExam !== "" &&
        createExamSchedule.totalUsers.length > 0 &&
        (["MCQ", "Practical"].includes(createExamSchedule.examType) ? 
        (parseInt(createExamSchedule.totalQuestion) ===
        parseInt(createExamSchedule.easy) +
        parseInt(createExamSchedule.medium) +
        parseInt(createExamSchedule.hard))
        :
        true)
      ) {
        let temp = [];
        const handleMultiSkill = () => {
          let validationMesssage = true;
          if (multiSkill) {
            let totalTimes = createExamSchedule.examType === "Practical";
            const uS = userSkills.forEach((i) => {
              let skill = subSkillsOptions.find((item) => item._id === i.skill);
              if (parseInt(i.pickQuestions) <= 0 || i.pickQuestions === "") {
                pickQuestionVaidation.push(true);
                validationMesssage = false;
              }
              if (
                parseInt(i.pickQuestions) !==
                parseInt(i.easy) + parseInt(i.medium) + parseInt(i.hard)
              ) {
                questionVaidation.push(true);
                validationMesssage = false;
              }
              if (!totalTimes) {
                if (parseInt(i.totalTimes) <= 0 || i.totalTimes === "") {
                  totalTimeVaidation.push(true);
                  validationMesssage = false;
                }
              }
              i.users.map((u) => {
                return temp.push({
                  user: u,
                  skill: i.skill,
                  totalQuestions: i.totalQuestions,
                  pickQuestions: i.pickQuestions,
                  totalTimes: totalTimes ? 0 : i.totalTimes,
                  easy: i.easy,
                  medium: i.medium,
                  hard: i.hard,
                  totalEasy: i.totalEasy,
                  totalMedium: i.totalMedium,
                  totalHard: i.totalHard,
                  subSkillTopicName: i.subSkillTopicName,
                });
              });
            });
            return validationMesssage;
          } else {
            return validationMesssage;
          }
        };
        const validation = await handleMultiSkill();
        if (
          !pickQuestionVaidation.includes(true) &&
          !totalTimeVaidation.includes(true) &&
          !questionVaidation.includes(true) &&
          validation
        ) {
          if (multiSkill ? createExamSchedule.totalUsers.length === temp.length ? true : window.confirm("Are you sure you want?") : true) {
            let pickQuestions = 0
            let easy = 0
            let medium = 0
            let hard = 0
            if(multiSkill){
              console.log(userSkills);
              userSkills.filter(skill => {
                pickQuestions += skill.pickQuestions
                easy += skill.easy
                medium += skill.medium
                hard += skill.hard
              })
            }
            else{
              pickQuestions = createExamSchedule.totalQuestion;
              easy = createExamSchedule.easy
              medium = createExamSchedule.medium
              hard = createExamSchedule.hard
            }
            CourseService.CreateExamSchedules(token, {
              ...createExamSchedule,
              userSkills: temp,
              subTopic: subTopicQuestion,
              totalQuestion: pickQuestions,
              easy: easy,
              medium: medium,
              hard: hard,
              subSkills: Array.from(selectedSubSkills, (i) => i._id),
            }).then((res) => {
              if (res.data.status) {
                getExamSchedule(1, true);
                setLoading(false);
                setConfirmBox(true);
                setMsg({
                  message: "Exam are created",
                  status: true,
                });
                setTimeout(() => {
                  setConfirmBox(false);
                }, 4000);
                setCreateExamSchedule(initialExamSchedule);
                setTotalQuestions({
                  length: 0,
                  easy: {},
                  medium: {},
                  hard: {},
                  status: false,
                });
                setMultiSkill(false);
                setSelectedSubSkills([]);
                setUserSkills([]);
                setSelectedUserSubSkills({});
                setSubTopicQuestion([]);
                setPickQuestionValidation([]);
                setTotalTimeValidation([]);
                setQuestionValidation([]);

              } else {
                window.scrollTo(0, 0);
                setTimeout(() => setConfirmBox(false), 4000);
                setLoading(false);
                setConfirmBox(true);
                setMsg({
                  message: res.data.message,
                  status: res.data.status,
                });
              }
            });
          } else {
            temp = []
          }
        } else {
          setMsg({
            message: `Please verify your 
                ${pickQuestionVaidation.includes(true) ? "Pick Question,  " : ""
              }
               ${totalTimeVaidation.includes(true) ? "Total Times,  " : ""}
               ${questionVaidation.includes(true)
                ? "add question number in easy, medium and hard,  "
                : ""
              }
              field`,
            success: false,
          });
          window.scrollTo(0, 0);
          setConfirmBox(true);
          setTimeout(() => setConfirmBox(false), 3000);
          setPickQuestionValidation([]);
          setTotalTimeValidation([]);
          setQuestionValidation([]);
        }
      } else {
        setMsg({
          message: `Please verify your ${createExamSchedule.skillId === "" ? "skillName, " : ""
            } ${createExamSchedule.batchId === "" ? "batchName,  " : ""} ${!multiSkill && createExamSchedule.totalQuestion === ""
              ? "totalQuestion,  "
              : ""
            }${!multiSkill && isNaN(createExamSchedule.totalQuestion)
              ? "totalQuestion value enter only numeric value ,  "
              : ""
            }
            ${createExamSchedule.examDate === "" ? "startDate, " : ""}${createExamSchedule.typeOfExam === "" ? "typeOfExam, " : ""
            }${createExamSchedule.sendEmail === "" ? "Email Send Or Not, " : ""}
            ${createExamSchedule.totalUsers.length == 0
              ? "select batch users, "
              : ""
            }
            ${createExamSchedule.endDate === "" ? "endDate, " : ""}
            ${!multiSkill &&
              parseInt(createExamSchedule.totalQuestion) > totalQuestions.length
              ? "Total questions cannot be more than availaible questions, "
              : ""
            }
            ${!multiSkill && !totalQuestions
              ? `No questions are availaible htmlFor ${createExamSchedule.skillId.label} skill, `
              : ""
            }${!multiSkill &&
              createExamSchedule.examType === "MCQ" &&
              !createExamSchedule.totalTime
              ? `Total Time, `
              : ""
            }${createExamSchedule.examType === ""
              ? "examType"
              : createExamSchedule.examType === "MCQ"
                ? !multiSkill && isNaN(createExamSchedule.totalTime)
                  ? "totalTime value enter only numeric value,  "
                  : ""
                : ""
            }${parseInt(createExamSchedule.totalQuestion) !==
              parseInt(createExamSchedule.easy) +
              parseInt(createExamSchedule.medium) +
              parseInt(createExamSchedule.hard)
              ? `easy medium and hard question count is totalQuestion, `
              : ""
            }
             field`,
          status: false,
        });
        window.scrollTo(0, 0);
        setConfirmBox(true);
        setTimeout(() => setConfirmBox(false), 3000);
      }
    }
  };

  const handleDeleteExam = async (examId) => {
    if (!token) {
      navigate("/login");
    } else {
      const confirm = window.confirm(
        "Are you sure, you want to delete this exam"
      );
      if (confirm) {
        CourseService.deleteExamSchedule(token, examId)
          .then((res) => {
            if (res.data.success) {
              const temp = [...trainerRequest];
              const batch = trainerRequest.findIndex((i) => i._id === examId);
              temp.splice(batch, 1);
              setTrainerRequest(temp);
              alert("Exam deleted successfully");
            }
          })
          .catch((err) => {
            console.log(err);
            alert("Error while deleting exam");
          });
      }
    }
  };

  const handleSubtopicQuestionValidation = () => {
    let errorhandle = true;
    for (const iterator of subTopicQuestion) {
      if (iterator["easy"] && iterator) {
        const val = Object.keys(iterator["easy"]);
        if (val?.length > 0) {
          val.forEach((element) => {
            if (
              iterator["easy"]?.[element] >
              totalQuestions["easy"]?.["easySubTopics"]?.[0]?.[element]
            ) {
              errorhandle = false;
            }
          });
        }
      }
      if (iterator["medium"] && iterator) {
        const val = Object.keys(iterator["medium"]);
        if (val?.length > 0) {
          val.forEach((element) => {
            // console.log(totalQuestions["medium"]["mediumSubTopics"][0][element])
            // // console.log(iterator["easy"][element])
            if (
              iterator["medium"]?.[element] >
              totalQuestions["medium"]?.["mediumSubTopics"]?.[0]?.[element]
            ) {
              errorhandle = false;
            }
          });
        }
      }
      if (iterator["hard"] && iterator) {
        const val = Object.keys(iterator["hard"]);
        if (val?.length > 0) {
          val.forEach((element) => {
            // console.log(totalQuestions["medium"]["mediumSubTopics"][0][element])
            // // console.log(iterator["easy"][element])
            if (
              iterator["hard"]?.[element] >
              totalQuestions["hard"]?.["hardSubTopics"]?.[0]?.[element]
            ) {
              errorhandle = false;
            }
          });
        }
      }
    }
    return errorhandle;
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      navigate("/login");
    } else {
      if (
        createExamSchedule.skillId !== "" &&
        createExamSchedule.batchId !== "" &&
        (multiSkill
          ? true
          : ((!isNaN(createExamSchedule.totalQuestion) &&
            createExamSchedule.examType === "MCQ" &&
            createExamSchedule.totalTime !== "" &&
            !isNaN(createExamSchedule.totalTime)) ||
            createExamSchedule.examType === "Practical") &&
          totalQuestions.status === true &&
          parseInt(createExamSchedule.totalQuestion) <=
          totalQuestions.length) &&
        createExamSchedule.endDate !== "" &&
        createExamSchedule.sendEmail !== "" &&
        createExamSchedule.examType !== "" &&
        createExamSchedule.typeOfExam !== "" &&
        createExamSchedule.totalUsers.length > 0 &&
        ["External", "Internal"].includes(createExamSchedule.examType) ?
        parseInt(createExamSchedule.totalQuestion) ===
        parseInt(createExamSchedule.easy) +
        parseInt(createExamSchedule.medium) +
        parseInt(createExamSchedule.hard) 
        :
        true &&
        handleSubtopicQuestionValidation()
      ) {
        let temp = [];
        const handleMultiSkill = () => {
          let validationMesssage = true;
          if (multiSkill) {
            let totalTimes = createExamSchedule.examType === "Practical";
            const uS = userSkills.forEach((i) => {
              let skill = subSkillsOptions.find((item) => item._id === i.skill);
              if (parseInt(i.pickQuestions) <= 0 || i.pickQuestions === "") {
                pickQuestionVaidation.push(true);
                validationMesssage = false;
              }
              if (
                parseInt(i.pickQuestions) !==
                parseInt(i.easy) + parseInt(i.medium) + parseInt(i.hard)
              ) {
                questionVaidation.push(true);
                validationMesssage = false;
              }
              if (!totalTimes) {
                if (parseInt(i.totalTimes) <= 0 || i.totalTimes === "") {
                  totalTimeVaidation.push(true);
                  validationMesssage = false;
                }
              }
              i.users.map((u) => {
                return temp.push({
                  user: u,
                  skill: i.skill,
                  totalQuestions: i.totalQuestions,
                  pickQuestions: i.pickQuestions,
                  totalTimes: totalTimes ? 0 : i.totalTimes,
                  easy: i.easy,
                  medium: i.medium,
                  hard: i.hard,
                  totalEasy: i.totalEasy,
                  totalMedium: i.totalMedium,
                  totalHard: i.totalHard,
                  subSkillTopicName: i.subSkillTopicName,
                });
              });
            });
            return validationMesssage;
          } else {
            return validationMesssage;
          }
        };
        const validation = await handleMultiSkill();
        if (
          !pickQuestionVaidation.includes(true) &&
          !totalTimeVaidation.includes(true) &&
          !questionVaidation.includes(true) &&
          validation
        ) {
          // console.log(temp)
          // setLoading(true);
          if (multiSkill ? createExamSchedule.totalUsers.length === temp.length ? true : window.confirm("Are you sure you want?") : true) {
            let pickQuestions = 0
            let easy = 0
            let medium = 0
            let hard = 0
            if(multiSkill){
              userSkills.filter(skill => {
                pickQuestions += skill.pickQuestions
                easy += skill.easy
                medium += skill.medium
                hard += skill.hard
              })
            }
            else{
              pickQuestions = createExamSchedule.totalQuestion;
              easy = createExamSchedule.easy
              medium = createExamSchedule.medium
              hard = createExamSchedule.hard
            }
            CourseService.updateExamSchedules(
              token,
              {
                ...createExamSchedule,
                userSkills: temp,
                subTopic: subTopicQuestion,
                totalQuestion: pickQuestions,
                easy: easy,
                medium: medium,
                hard: hard,
                subSkills: Array.from(selectedSubSkills, (i) => i._id),
              },
              createExamId
            ).then((res) => {
              if (res.data.status) {
                getExamSchedule(1, true);
                setLoading(false);
                setConfirmBox(true);
                setMsg({
                  message: "Exam are updated",
                  status: true,
                });
                setTimeout(() => setConfirmBox(false), 4000);
                setCreateExamSchedule(initialExamSchedule);
                setTotalQuestions({
                  length: 0,
                  easy: {},
                  medium: {},
                  hard: {},
                  status: false,
                });
                setMultiSkill(false);
                setSelectedSubSkills([]);
                setUserSkills([]);
                setSelectedUserSubSkills({});
                setUpdateData("");
                setSubTopicQuestion([]);
                setPickQuestionValidation([]);
                localStorage.removeItem("subTopicList");
                setTotalTimeValidation([]);
                setQuestionValidation([]);
              } else {
                window.scrollTo(0, 0);
                setTimeout(() => setConfirmBox(false), 10000);
                setLoading(false);
                setConfirmBox(true);
                setMsg({
                  message: res.data.message,
                  status: false,
                });
              }
            });
          } else {
            temp = []
          }

        } else {
          setMsg({
            message: `Please verify your 
                ${pickQuestionVaidation.includes(true) ? "Pick Question,  " : ""
              }
               ${totalTimeVaidation.includes(true) ? "Total Times,  " : ""}${questionVaidation.includes(true)
                ? "add question number in easy, medium and hard,  "
                : ""
              }
              field`,
            success: false,
          });
          window.scrollTo(0, 0);
          setConfirmBox(true);
          setTimeout(() => setConfirmBox(false), 10000);
          setPickQuestionValidation([]);
          setTotalTimeValidation([]);
          setQuestionValidation([]);
        }
      } else {
        setMsg({
          message: `Please verify your ${createExamSchedule.skillId === "" ? "skillName, " : ""
            }${handleSubtopicQuestionValidation()
              ? ""
              : "subtopic question value greater than the subtopic question count required,"
            } ${createExamSchedule.batchId === "" ? "batchName,  " : ""} 
              ${!multiSkill && createExamSchedule.totalQuestion === ""
              ? "totalQuestion,  "
              : ""
            }${!multiSkill && isNaN(createExamSchedule.totalQuestion)
              ? "totalQuestion value enter only numeric value ,  "
              : ""
            }
            ${createExamSchedule.examDate === "" ? "startDate, " : ""}${createExamSchedule.typeOfExam === "" ? "typeOfExam, " : ""
            }${createExamSchedule.sendEmail === "" ? "Email Send Or Not, " : ""}
            ${createExamSchedule.totalUsers.length == 0
              ? "select batch users, "
              : ""
            }
            ${createExamSchedule.endDate === "" ? "endDate, " : ""}
            ${!multiSkill &&
              parseInt(createExamSchedule.totalQuestion) > totalQuestions.length
              ? "Total questions cannot be more than availaible questions, "
              : ""
            }
            ${!multiSkill && !totalQuestions
              ? `No questions are availaible htmlFor ${createExamSchedule.skillId.label} skill, `
              : ""
            }${!multiSkill &&
              createExamSchedule.examType === "MCQ" &&
              !createExamSchedule.totalTime
              ? `Total Time, `
              : ""
            }${createExamSchedule.examType === ""
              ? "examType"
              : createExamSchedule.examType === "MCQ"
                ? !multiSkill && isNaN(createExamSchedule.totalTime)
                  ? "totalTime value enter only numeric value,  "
                  : ""
                : ""
            }${!multiSkill &&
              createExamSchedule.examType === "MCQ" &&
              !createExamSchedule.totalTime
              ? `Total Time, `
              : ""
            }${parseInt(createExamSchedule.totalQuestion) !==
              parseInt(createExamSchedule.easy) +
              parseInt(createExamSchedule.medium) +
              parseInt(createExamSchedule.hard)
              ? `easy medium and hard question count is totalQuestion, `
              : ""
            }
             field`,
          status: false,
        });
        window.scrollTo(0, 0);
        setConfirmBox(true);
      }
    }
  };

  const handleCheckbox = (e) => {
    setCreateExamSchedule({
      ...createExamSchedule,
      markAsCompleted: e.target.checked,
    });
  };

  const handleTableCheckbox = async (e, data) => {
    if (e.target.checked) {
      if (token) {
        let res = await CourseService.getMergeVideos(
          data.skillId.name,
          data.batchId.name,
          data.examId,
          token
        );
        let updateMark;
        if (res.data.status === "fail") {
          document.getElementById("checkbox").checked = false;
          updateMark = {
            markAsCompleted: false,
          };
        } else {
          updateMark = {
            markAsCompleted: true,
          };
        }
        let res2 = await CourseService.updateExamSchedules(
          token,
          updateMark,
          data.examId
        );
        setConfirmBox(true);
      } else {
        navigate("/login");
      }
    }
  };

  const handleChangeExamStatus = (e) => {
    if (e.target.checked) {
      const userIdAdd = batchUserData.filter((item) =>
        createExamSchedule.totalUsers.push(item._id)
      );
      const users = userSkills.map((item) => {
        if (item.users) {
          return { ...item, users: createExamSchedule.totalUsers };
        }
        return item;
      });
      setUserSkills(users);
      setSelectAll(true);
    } else {
      const users = userSkills.map((item) => {
        if (item.users) {
          return { ...item, users: [] };
        }
        return item;
      });
      setUserSkills(users);
      setCreateExamSchedule({
        ...createExamSchedule,
        totalUsers: [],
      });
      setSelectAll(false);
    }
  };

  const DateFormat = (dateValue) => {
    let date = new Date(dateValue);
    return `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
      }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`} ${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
      }:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}`;
  };

  const handleChangeParticularUser = (checked, userId) => {
    // console.log(e.target.checked, item)
    if (checked) {
      let newArr = createExamSchedule.totalUsers; // copying the old datas array
      newArr.push(userId);
      if (multiSkill) {
        userSkills.map((userSkillData) => {
          const addUsers = userSkillData.users.concat([userId])
          userSkillData['users'] = addUsers
          return userSkillData
        })
      }
      const userLength = batchUserData.length;
      const userTrue = createExamSchedule.totalUsers.length;
      if (userLength == userTrue) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }

      setCreateExamSchedule({ ...createExamSchedule, totalUsers: newArr });

    } else {
      const index = createExamSchedule.totalUsers.findIndex((object) => {
        return object === userId;
      });
      let newArr = createExamSchedule.totalUsers;
      newArr.splice(index, 1);

      setCreateExamSchedule({
        ...createExamSchedule,
        totalUsers: newArr,
      });
      const userLength = batchUserData.length;
      const userTrue = createExamSchedule.totalUsers.length;
      if (userLength === userTrue) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      if (multiSkill) {
        userSkills.map((userSkillData) => {
          const removeUser = userSkillData.users.filter((user) => user !== userId)
          userSkillData.users = removeUser
          return userSkillData
        })
      }


      // console.log(userSkills)
    }
  };

  const handleUserSubSkill = (checked, userId) => {
    const temp = [...userSkills];
    if (!checked) {
      const foundUser = temp.find(
        (i) => i.skill === selectedUserSubSkills._id && i.users.includes(userId)
      );
      foundUser.users.splice(foundUser.users.indexOf(userId), 1);
      if (temp.filter((user) => user.users.includes(userId)).length === 0) {
        handleChangeParticularUser(false, userId)
      }
    }
    if (checked) {
      const foundUser = temp.find((i) => i.skill === selectedUserSubSkills._id);
      foundUser.users.push(userId);
    }
    setUserSkills(temp);
  };

  const getCheckValueInUserSkills = (userId, skillId) => {
    return userSkills.find(
      (i) => i.skill === skillId && i?.users?.includes(userId)
    )
      ? true
      : false;
  };
  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage({ selected: 1, choice: "" });
              }}
            >
              {updateData === "" ? "Create Schedule" : "Update Schedule"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="container p-2 px-3 mt-2">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="200" />
              </div>
              <div className="col-12 text-white h4">
                Uploading
                {updateData === "" ? "Create Schedule" : "Update Schedule"}
              </div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${msg.status ? "alert-success" : "alert-danger"
                    }`}
                >
                  {msg.message}
                </div>
              </div>
            </div>
          </div>
        )}

        <h4 className={page.selected === 1 ? "mt-2 text-center" : "d-none"}>
          {updateData === "" ? "Create Exam Schedule" : "Update Exam Schedule"}
        </h4>

        <div className="mb-3">
          <form className="px-lg-5 py-2 row" id="myform">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 position-relative my-2">
                <label htmlFor="scname" className="form-label">
                  Select Batch
                </label>
                <Select
                  options={batchesOptions}
                  value={createExamSchedule.batchId}
                  isDisabled={updateData !== "" ? true : false}
                  placeholder={"Select Batch"}
                  isSearchable
                  onChange={handleBatch}
                />
              </div>
              {createExamSchedule.batchId !== "" &&
                subCourseBatches.length > 0 && (
                  <>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 position-relative my-2">
                      <label htmlFor="scname" className="form-label">
                        Skill Name
                      </label>
                      <Select
                        options={subCourseBatches}
                        value={createExamSchedule.skillId}
                        placeholder={"Select Skill"}
                        isSearchable
                        onChange={handleSkill}
                      />
                    </div>
                    <div className="form-check form-switch my-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="multiSkill"
                        value={multiSkill}
                        checked={multiSkill}
                        onChange={() => {
                          setMultiSkill(!multiSkill);
                          setCreateExamSchedule({
                            ...createExamSchedule,
                            totalQuestion: 0,
                          });
                        }}
                      />
                      <label className="form-check-label" for="multiSkill">
                        Multiple Skills
                      </label>
                    </div>
                  </>
                )}
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 position-relative my-2">
                <label htmlFor="scname" className="form-label">
                  Type Of Exam
                </label>
                {batches && batches.length > 0 && (
                  <Select
                    options={typeOf}
                    value={createExamSchedule.typeOfExam}
                    placeholder={"Select Type"}
                    isSearchable
                    onChange={handleType}
                  />
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 position-relative mt-3 mb-2">
                <label htmlFor="scname" className="form-label me-3">
                  Exam Type ? :
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={createExamSchedule.examType === "MCQ"}
                    value="MCQ"
                    id="mcq"
                    onClick={() =>
                      setCreateExamSchedule({
                        ...createExamSchedule,
                        examType: "MCQ",
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="mcq">
                    MCQ
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={createExamSchedule.examType === "Practical"}
                    value="Practical"
                    id="practical"
                    onClick={() =>
                      setCreateExamSchedule({
                        ...createExamSchedule,
                        examType: "Practical",
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="practical">
                    Practical
                  </label>
                </div>
                {
                  createExamSchedule?.typeOfExam.value === "External" && (
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={createExamSchedule.examType === "Meeting"}
                        value="Meeting"
                        id="meeting"
                        onClick={() =>
                          setCreateExamSchedule({
                            ...createExamSchedule,
                            examType: "Meeting",
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="meeting">
                        Meeting
                      </label>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              {createExamSchedule.batchId &&
                batchUser &&
                batchUserData &&
                batchUserData.length > 0 ? (
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-12 position-relative my-2 bg-light"
                  style={{
                    height: batchUserData.length > 5 ? "300px" : "200px",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="my-2 mx-2">
                      <div className="d-flex">
                        <div className="me-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter User Name"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e.target.value)}
                          />
                        </div>
                        {multiSkill && (
                          <div>
                            <Select
                              options={selectedSubSkills}
                              onChange={(e) => {
                                setSelectedUserSubSkills(e);
                              }}
                              value={selectedUserSubSkills}
                              placeholder={"Select skill"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="form-check my-2 me-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectAll}
                          onChange={(e) => handleChangeExamStatus(e)}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Select All
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: batchUserData.length > 5 ? "235px" : "135px",
                    }}
                  >
                    {batchUser[0] &&
                      batchUserData
                        .filter((item1) => {
                          if (searchUser === "") {
                            return item1;
                          } else if (
                            item1.username
                              .toLowerCase()
                              .includes(searchUser.toLowerCase())
                          ) {
                            return item1;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <div>
                              {item.deactivate === false && (
                                <div key={index} className="row px-2">
                                  <div className="col col-6 d-flex">
                                    <div className="form-check form-switch me-3">
                                      <label
                                        className="form-check-label"
                                        htmlFor={"attendance" + index}
                                      >
                                        {item.username}
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={createExamSchedule.totalUsers.includes(
                                          item._id
                                        )}
                                        id={"attendance" + index}
                                        onChange={(e) => {
                                          handleChangeParticularUser(
                                            e.target.checked,
                                            item._id
                                          );
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <small>{item.empId}</small>
                                    </div>
                                  </div>
                                  {multiSkill &&
                                    selectedSubSkills.length > 0 &&
                                    createExamSchedule.totalUsers.includes(
                                      item._id
                                    ) &&
                                    selectedUserSubSkills.value && (
                                      <div className="col col-6">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            // value={getCheckValueInUserSkills(
                                            //   item._id,
                                            //   selectedUserSubSkills._id
                                            // )}
                                            checked={getCheckValueInUserSkills(
                                              item._id,
                                              selectedUserSubSkills._id
                                            )}
                                            onChange={(e) =>
                                              handleUserSubSkill(
                                                e.target.checked,
                                                item._id
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                  </div>
                </div>
              ) : (
                createExamSchedule.batchId &&
                batchUser &&
                batchUserData.length == 0 && (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-danger mt-5">
                    <h5>No users Available For this batch</h5>
                  </div>
                )
              )}
            </div>
            {multiSkill &&
              createExamSchedule.examType &&
              createExamSchedule.typeOfExam.value && (
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 position-relative my-2">
                  <label htmlFor="subSkills" className="form-label">
                    Select Sub Skills
                  </label>
                  <Select
                    options={subSkillsOptions}
                    value={selectedSubSkills}
                    placeholder={"Please Select Sub skills"}
                    isSearchable
                    onChange={(e) => {
                      setSelectedSubSkills([...e]);
                      const userSkills = e.map((i) => {
                        return {
                          users: [...createExamSchedule.totalUsers],
                          skill: i._id,
                        };
                      });
                      if (
                        !userSkills.find(
                          (i) => i.skill === selectedUserSubSkills._id
                        )
                      ) {
                        setSelectedUserSubSkills({});
                      }
                      getTotalQuestions([...userSkills]);
                    }}
                    isMulti
                  />
                </div>
              )}
            {
              createExamSchedule.examType !== "Meeting" &&
              (
                !multiSkill ? (
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 position-relative">
                  <label htmlFor="scname" className="form-label">
                    Total Question Show
                  </label>
                  <input
                    type="text"
                    inputmode="numeric"
                    className="form-control"
                    placeholder="Select total question numbers"
                    disabled={
                      totalQuestions.easy?.easyQuestionLength > 0 ||
                        totalQuestions.medium?.mediumQuestionLength > 0 ||
                        totalQuestions.hard?.hardQuestionLength > 0
                        ? true
                        : false
                    }
                    value={createExamSchedule.totalQuestion}
                    onChange={(e) =>
                      setCreateExamSchedule({
                        ...createExamSchedule,
                        totalQuestion: e.target.value,
                      })
                    }
                  />
                  {totalQuestions &&
                    totalQuestions.length > 0 &&
                    totalQuestions.status === true ? (
                    <p className="text-success">
                      Your Available Questions {totalQuestions.length}
                    </p>
                  ) : (
                    createExamSchedule.skillId !== "" &&
                    createExamSchedule.typeOfExam !== "" &&
                    createExamSchedule.examType !== "" && (
                      <p className="text-danger">No Available Questions</p>
                    )
                  )}
                </div>
                ) : (
                  <div>
                    {userSkills.map((userSkill, index) => {
                      const getSkill = selectedSubSkills.find(
                        (i) => i._id === userSkill.skill
                      );
                      if (getSkill) {
                        const skillname = getSkill.value;
                        return (
                          <div
                            className="row col-12 col-md-12 col-lg-12 mb-2"
                            key={index}
                          >
                            <div className="col col-2">
                              <p className="m-0 p-0">{skillname}</p>
                            </div>
                            <div className="col col-10 row">
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 position-relative">
                                <label htmlFor="scname" className="form-label">
                                  Total Question Show
                                </label>
                                <input
                                  type="text"
                                  inputmode="numeric"
                                  className="form-control"
                                  placeholder="Please Select total question numbers"
                                  value={userSkill.pickQuestions}
                                  disabled={
                                    userSkill.totalEasy?.easyQuestionLength > 0 ||
                                      userSkill.totalMedium?.mediumQuestionLength >
                                      0 ||
                                      userSkill.totalHard?.hardQuestionLength > 0
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const temp = [...userSkills];
                                    const getSkill = temp.find(
                                      (i) => i.skill === userSkill.skill
                                    );
                                    getSkill.subSkillTopicName = handleSubTopicList(
                                      {
                                        easy: getSkill.totalEasy,
                                        medium: getSkill.totalMedium,
                                        hard: getSkill.totalHard,
                                      }
                                    );
                                    getSkill.easy = 0;
                                    getSkill.medium = 0;
                                    getSkill.hard = 0;
                                    getSkill.pickQuestions =
                                      e.target.value <= getSkill.totalQuestions
                                        ? e.target.value
                                        : 0;
                                    setUserSkills(temp);
                                  }}
                                />
                                <p className="text-success">
                                  AQ:
                                  {userSkill.totalQuestions}
                                </p>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 position-relative">
                                {createExamSchedule.examType !== "Practical" && (
                                  <>
                                    <label htmlFor="scnam" className="form-label">
                                      Total Exam Time (define Minutes)
                                    </label>
                                    <input
                                      type="number"
                                      inputmode="numeric"
                                      className="form-control"
                                      placeholder="Please Select total question numbers"
                                      value={userSkill.totalTimes}
                                      onChange={(e) => {
                                        const temp = [...userSkills];
                                        const getSkill = temp.find(
                                          (i) => i.skill === userSkill.skill
                                        );
                                        getSkill.totalTimes = e.target.value;
                                        setUserSkills(temp);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <div className="col-3">
                                <label
                                  htmlFor="easyQuestion"
                                  className="form-label"
                                >
                                  Easy
                                </label>
                                <input
                                  type="text"
                                  inputmode="numeric"
                                  className="form-control"
                                  id="easyQuestion"
                                  value={userSkill.easy}
                                  disabled={
                                    userSkill.totalEasy?.easySubTopics?.length > 0
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const temp = [...userSkills];
                                    const getSkill = temp.find(
                                      (i) => i.skill === userSkill.skill
                                    );
                                    getSkill.pickQuestions =
                                      parseInt(e.target.value) <=
                                        parseInt(getSkill.totalQuestions) &&
                                        parseInt(e.target.value) <=
                                        parseInt(
                                          getSkill.totalEasy.easyQuestionLength
                                        ) &&
                                        parseInt(e.target.value) <=
                                        parseInt(e.target.value) +
                                        parseInt(getSkill.medium) +
                                        parseInt(getSkill.hard)
                                        ? parseInt(e.target.value) +
                                        parseInt(getSkill.medium) +
                                        parseInt(getSkill.hard)
                                        : parseInt(getSkill.medium) +
                                        parseInt(getSkill.hard);
                                    getSkill.easy =
                                      parseInt(e.target.value) <=
                                        parseInt(
                                          getSkill.totalEasy.easyQuestionLength
                                        ) &&
                                        parseInt(e.target.value) +
                                        parseInt(getSkill.medium) +
                                        parseInt(getSkill.hard) <=
                                        parseInt(getSkill.totalQuestions)
                                        ? parseInt(e.target.value)
                                        : 0;
                                    setUserSkills(temp);
                                  }}
                                />
                                <p className="text-success">
                                  AQ:
                                  {userSkill.totalEasy?.easyQuestionLength
                                    ? userSkill.totalEasy.easyQuestionLength
                                    : 0}
                                </p>
                              </div>
                              {userSkill.totalEasy?.easySubTopics?.length > 0 ? (
                                <div class="col col-9 row">
                                  {userSkill.totalEasy?.easySubTopics?.length > 0 &&
                                    userSkill.totalEasy.easySubTopics.map(
                                      (topic, index) => {
                                        const findObject =
                                          userSkill.subSkillTopicName.filter(
                                            (item) =>
                                              Object.keys(item)[0] === "easy"
                                          )[0];
                                        return (
                                          <div className="col col-3">
                                            <label
                                              htmlFor="easyQuestion"
                                              className="form-label"
                                            >
                                              {Object.keys(topic)[0]}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="easyQuestion"
                                              name={Object.keys(topic)[0]}
                                              value={
                                                findObject.easy[
                                                Object.keys(topic)[0]
                                                ]
                                              }
                                              onChange={(e) => {
                                                const names = e.target.name;
                                                const temp = [...userSkills];
                                                let totalSum = 0;
                                                const getSkill = temp.find(
                                                  (i) => i.skill === userSkill.skill
                                                );
                                                const getTopic =
                                                  getSkill.subSkillTopicName.filter(
                                                    (item) =>
                                                      Object.keys(item)[0] ===
                                                      "easy"
                                                  )[0];

                                                const findNumber =
                                                  getSkill.totalEasy.easySubTopics.find(
                                                    (item) =>
                                                      Object.keys(item)[0] === names
                                                  );
                                                const topics = getTopic.easy;
                                                for (let name in topics) {
                                                  if (name !== names) {
                                                    totalSum += parseInt(
                                                      topics[name]
                                                    );
                                                  }
                                                }
                                                getSkill.pickQuestions =
                                                  totalSum +
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      Object.values(findNumber)[0]
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalEasy
                                                        .easyQuestionLength
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(e.target.value) +
                                                    parseInt(getSkill.medium) +
                                                    parseInt(getSkill.hard)
                                                    ? parseInt(e.target.value) +
                                                    parseInt(totalSum) +
                                                    parseInt(getSkill.medium) +
                                                    parseInt(getSkill.hard)
                                                    : totalSum +
                                                    parseInt(getSkill.medium) +
                                                    parseInt(getSkill.hard);
                                                getSkill.easy =
                                                  totalSum +
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(getSkill.medium) +
                                                    totalSum +
                                                    parseInt(getSkill.hard) +
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      Object.values(findNumber)[0]
                                                    )
                                                    ? totalSum +
                                                    parseInt(e.target.value)
                                                    : totalSum;
                                                getTopic.easy[names] =
                                                  parseInt(e.target.value) <=
                                                    parseInt(
                                                      Object.values(findNumber)[0]
                                                    ) &&
                                                    parseInt(e.target.value) +
                                                    totalSum +
                                                    parseInt(getSkill.medium) +
                                                    parseInt(getSkill.hard) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(getSkill.easy) +
                                                    parseInt(getSkill.medium) +
                                                    parseInt(getSkill.hard) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    )
                                                    ? parseInt(e.target.value)
                                                    : 0;

                                                setUserSkills(temp);
                                              }}
                                            />
                                            <p className="text-success">
                                              AQ:{Object.values(topic)[0]}
                                            </p>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              ) : (
                                userSkill.totalMedium?.mediumSubTopics?.length >
                                0 && <div className="col col-9 row"></div>
                              )}
                              <div className="col-3">
                                <label
                                  htmlFor="mediumQuestion"
                                  className="form-label"
                                >
                                  Medium
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  inputmode="numeric"
                                  id="mediumQuestion"
                                  value={userSkill.medium}
                                  disabled={
                                    userSkill.totalMedium?.mediumSubTopics?.length >
                                      0
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const temp = [...userSkills];
                                    const getSkill = temp.find(
                                      (i) => i.skill === userSkill.skill
                                    );
                                    getSkill.pickQuestions =
                                      parseInt(e.target.value) <=
                                        parseInt(getSkill.totalQuestions) &&
                                        parseInt(e.target.value) <=
                                        parseInt(
                                          getSkill.totalMedium.mediumQuestionLength
                                        ) &&
                                        parseInt(e.target.value) <=
                                        parseInt(e.target.value) +
                                        parseInt(getSkill.easy) +
                                        parseInt(getSkill.hard)
                                        ? parseInt(e.target.value) +
                                        parseInt(getSkill.easy) +
                                        parseInt(getSkill.hard)
                                        : parseInt(getSkill.easy) +
                                        parseInt(getSkill.hard);
                                    getSkill.medium =
                                      parseInt(e.target.value) <=
                                        parseInt(
                                          getSkill.totalMedium.mediumQuestionLength
                                        ) &&
                                        parseInt(getSkill.easy) +
                                        parseInt(e.target.value) +
                                        parseInt(getSkill.hard) <=
                                        parseInt(getSkill.totalQuestions)
                                        ? parseInt(e.target.value)
                                        : 0;
                                    setUserSkills(temp);
                                  }}
                                />
                                <p className="text-success">
                                  AQ:
                                  {userSkill.totalMedium?.mediumQuestionLength
                                    ? userSkill.totalMedium.mediumQuestionLength
                                    : 0}
                                </p>
                              </div>
                              {userSkill.totalMedium?.mediumSubTopics?.length >
                                0 ? (
                                <div class="col col-9 row">
                                  {userSkill.totalMedium?.mediumSubTopics?.length >
                                    0 &&
                                    userSkill.totalMedium.mediumSubTopics.map(
                                      (topic, index) => {
                                        const findObject =
                                          userSkill.subSkillTopicName.filter(
                                            (item) =>
                                              Object.keys(item)[0] === "medium"
                                          )[0];
                                        return (
                                          <div className="col-3">
                                            <label
                                              htmlFor="easyQuestion"
                                              className="form-label"
                                            >
                                              {Object.keys(topic)[0]}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="easyQuestion"
                                              name={Object.keys(topic)[0]}
                                              value={
                                                findObject.medium[
                                                Object.keys(topic)[0]
                                                ]
                                              }
                                              onChange={(e) => {
                                                const names = e.target.name;
                                                const temp = [...userSkills];
                                                let totalSum = 0;
                                                const getSkill = temp.find(
                                                  (i) => i.skill === userSkill.skill
                                                );

                                                const getTopic =
                                                  getSkill.subSkillTopicName.filter(
                                                    (item) =>
                                                      Object.keys(item)[0] ===
                                                      "medium"
                                                  )[0];

                                                const findNumber =
                                                  getSkill.totalMedium.mediumSubTopics.find(
                                                    (item) =>
                                                      Object.keys(item)[0] === names
                                                  );
                                                const topics = getTopic.medium;
                                                for (let name in topics) {
                                                  if (name !== names) {
                                                    totalSum += parseInt(
                                                      topics[name]
                                                    );
                                                  }
                                                }

                                                getSkill.pickQuestions =
                                                  totalSum +
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      Object.values(findNumber)[0]
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalMedium
                                                        .mediumQuestionLength
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(e.target.value) +
                                                    parseInt(getSkill.easy) +
                                                    parseInt(getSkill.hard)
                                                    ? parseInt(e.target.value) +
                                                    parseInt(totalSum) +
                                                    parseInt(getSkill.easy) +
                                                    parseInt(getSkill.hard)
                                                    : totalSum +
                                                    parseInt(getSkill.easy) +
                                                    parseInt(getSkill.hard);

                                                getSkill.medium =
                                                  totalSum +
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(getSkill.easy) +
                                                    totalSum +
                                                    parseInt(getSkill.hard) +
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(e.target.value) <=
                                                    parseInt(
                                                      Object.values(findNumber)[0]
                                                    )
                                                    ? totalSum +
                                                    parseInt(e.target.value)
                                                    : totalSum;

                                                getTopic.medium[names] =
                                                  parseInt(e.target.value) <=
                                                    parseInt(
                                                      Object.values(findNumber)[0]
                                                    ) &&
                                                    parseInt(e.target.value) +
                                                    totalSum +
                                                    parseInt(getSkill.easy) +
                                                    parseInt(getSkill.hard) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    ) &&
                                                    parseInt(getSkill.easy) +
                                                    parseInt(getSkill.medium) +
                                                    parseInt(getSkill.hard) <=
                                                    parseInt(
                                                      getSkill.totalQuestions
                                                    )
                                                    ? parseInt(e.target.value)
                                                    : 0;

                                                setUserSkills(temp);
                                              }}
                                            />
                                            <p className="text-success">
                                              AQ:{Object.values(topic)[0]}
                                            </p>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              ) : (
                                userSkill.totalHard?.hardSubTopics?.length > 0 && (
                                  <div className="col col-9 row"></div>
                                )
                              )}
                              <div className="col-3">
                                <label
                                  htmlFor="hardQuestion"
                                  className="form-label"
                                >
                                  Hard
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  inputmode="numeric"
                                  id="hardQuestion"
                                  value={userSkill.hard}
                                  disabled={
                                    userSkill.totalHard?.hardSubTopics?.length > 0
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const temp = [...userSkills];
                                    const getSkill = temp.find(
                                      (i) => i.skill === userSkill.skill
                                    );
                                    getSkill.pickQuestions =
                                      parseInt(e.target.value) <=
                                        parseInt(getSkill.totalQuestions) &&
                                        parseInt(e.target.value) <=
                                        parseInt(
                                          getSkill.totalHard.hardQuestionLength
                                        ) &&
                                        parseInt(e.target.value) <=
                                        parseInt(e.target.value) +
                                        parseInt(getSkill.medium) +
                                        parseInt(getSkill.easy)
                                        ? parseInt(e.target.value) +
                                        parseInt(getSkill.medium) +
                                        parseInt(getSkill.easy)
                                        : parseInt(getSkill.medium) +
                                        parseInt(getSkill.easy);
                                    getSkill.hard =
                                      parseInt(e.target.value) <=
                                        parseInt(
                                          getSkill.totalHard.hardQuestionLength
                                        ) &&
                                        parseInt(getSkill.easy) +
                                        parseInt(getSkill.medium) +
                                        parseInt(e.target.value) <=
                                        parseInt(getSkill.pickQuestions)
                                        ? parseInt(e.target.value)
                                        : 0;
                                    setUserSkills(temp);
                                  }}
                                />
                                <p className="text-success">
                                  AQ:
                                  {userSkill.totalHard?.hardQuestionLength
                                    ? userSkill.totalHard.hardQuestionLength
                                    : 0}
                                </p>
                              </div>

                              {userSkill.totalHard?.hardSubTopics?.length > 0 && (
                                <div class="col col-9 row">
                                  {userSkill.totalHard.hardSubTopics.map(
                                    (topic, index) => {
                                      const findObject =
                                        userSkill.subSkillTopicName.filter(
                                          (item) => Object.keys(item)[0] === "hard"
                                        )[0];
                                      return (
                                        <div className="col-3">
                                          <label
                                            htmlFor="easyQuestion"
                                            className="form-label"
                                          >
                                            {Object.keys(topic)[0]}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="easyQuestion"
                                            name={Object.keys(topic)[0]}
                                            value={
                                              findObject.hard[Object.keys(topic)[0]]
                                            }
                                            onChange={(e) => {
                                              const names = e.target.name;
                                              const temp = [...userSkills];
                                              let totalSum = 0;
                                              const getSkill = temp.find(
                                                (i) => i.skill === userSkill.skill
                                              );
                                              const getTopic =
                                                getSkill.subSkillTopicName.filter(
                                                  (item) =>
                                                    Object.keys(item)[0] === "hard"
                                                )[0];
                                              const findNumber =
                                                getSkill.totalHard.hardSubTopics.find(
                                                  (item) =>
                                                    Object.keys(item)[0] === names
                                                );
                                              const topics = getTopic.hard;
                                              for (let name in topics) {
                                                if (name !== names) {
                                                  totalSum += parseInt(
                                                    topics[name]
                                                  );
                                                }
                                              }
                                              getSkill.pickQuestions =
                                                totalSum +
                                                  parseInt(e.target.value) <=
                                                  parseInt(
                                                    getSkill.totalQuestions
                                                  ) &&
                                                  parseInt(e.target.value) <=
                                                  parseInt(
                                                    Object.values(findNumber)[0]
                                                  ) &&
                                                  parseInt(e.target.value) <=
                                                  parseInt(
                                                    getSkill.totalQuestions
                                                  ) &&
                                                  parseInt(e.target.value) <=
                                                  parseInt(
                                                    getSkill.totalHard
                                                      .hardQuestionLength
                                                  ) &&
                                                  parseInt(e.target.value) <=
                                                  parseInt(e.target.value) +
                                                  parseInt(getSkill.medium) +
                                                  parseInt(getSkill.easy)
                                                  ? parseInt(e.target.value) +
                                                  parseInt(totalSum) +
                                                  parseInt(getSkill.medium) +
                                                  parseInt(getSkill.easy)
                                                  : totalSum +
                                                  parseInt(getSkill.medium) +
                                                  parseInt(getSkill.easy);
                                              getSkill.hard =
                                                totalSum +
                                                  parseInt(e.target.value) <=
                                                  parseInt(
                                                    getSkill.totalQuestions
                                                  ) &&
                                                  parseInt(getSkill.medium) +
                                                  totalSum +
                                                  parseInt(getSkill.easy) +
                                                  parseInt(e.target.value) <=
                                                  parseInt(
                                                    getSkill.totalQuestions
                                                  ) &&
                                                  parseInt(e.target.value) <=
                                                  parseInt(
                                                    Object.values(findNumber)[0]
                                                  )
                                                  ? totalSum +
                                                  parseInt(e.target.value)
                                                  : totalSum;

                                              getTopic.hard[names] =
                                                parseInt(e.target.value) <=
                                                  parseInt(
                                                    Object.values(findNumber)[0]
                                                  ) &&
                                                  parseInt(e.target.value) +
                                                  totalSum +
                                                  parseInt(getSkill.medium) +
                                                  parseInt(getSkill.easy) <=
                                                  parseInt(
                                                    getSkill.totalQuestions
                                                  ) &&
                                                  parseInt(getSkill.easy) +
                                                  parseInt(getSkill.medium) +
                                                  parseInt(getSkill.hard) <=
                                                  parseInt(getSkill.totalQuestions)
                                                  ? parseInt(e.target.value)
                                                  : 0;
                                              setUserSkills(temp);
                                            }}
                                          />
                                          <p className="text-success">
                                            AQ:{Object.values(topic)[0]}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                      return <></>;
                    })}
                  </div>
                )
              )
            }
            {
              createExamSchedule.examType !== "Meeting" &&
               (
                (createExamSchedule.examType === "MCQ" && !multiSkill && (
                  <>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 position-relative">
                      <label htmlFor="scname" className="form-label">
                        Total Exam Time (define Minutes)
                      </label>
                      <input
                        type="number"
                        inputmode="numeric"
                        className="form-control"
                        placeholder="Please Select exam duration"
                        value={createExamSchedule.totalTime}
                        onChange={(e) =>
                          setCreateExamSchedule({
                            ...createExamSchedule,
                            totalTime: e.target.value,
                          })
                        }
                      />
                    </div>
                  </>
                ))
              )
            }
            {
              createExamSchedule.examType !== "Meeting" &&
                (
                (!multiSkill && (
                  <>
                    <div className="row">
                      <div className="col col-3">
                        <label htmlFor="easyQuestion" className="form-label">
                          Easy
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="easyQuestion"
                          value={createExamSchedule.easy}
                          disabled={
                            totalQuestions.easy?.easySubTopics?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setCreateExamSchedule({
                              ...createExamSchedule,
                              totalQuestion:
                                parseInt(e.target.value) <=
                                  parseInt(totalQuestions.length) &&
                                  parseInt(e.target.value) <=
                                  parseInt(
                                    totalQuestions.easy.easyQuestionLength
                                  ) &&
                                  parseInt(e.target.value) <=
                                  parseInt(e.target.value) +
                                  parseInt(createExamSchedule.medium) +
                                  parseInt(createExamSchedule.hard)
                                  ? parseInt(e.target.value) +
                                  parseInt(createExamSchedule.medium) +
                                  parseInt(createExamSchedule.hard)
                                  : parseInt(createExamSchedule.medium) +
                                  parseInt(createExamSchedule.hard),
                              easy:
                                parseInt(e.target.value) <=
                                  parseInt(
                                    totalQuestions.easy.easyQuestionLength
                                  ) &&
                                  parseInt(e.target.value) +
                                  parseInt(createExamSchedule.medium) +
                                  parseInt(createExamSchedule.hard) <=
                                  parseInt(totalQuestions.length)
                                  ? parseInt(e.target.value)
                                  : 0,
                            });
                          }}
                        />
                        <p className="text-success">
                          AQ:
                          {totalQuestions.easy?.easyQuestionLength
                            ? totalQuestions.easy.easyQuestionLength
                            : 0}
                        </p>
                      </div>
                      {totalQuestions.easy?.easySubTopics?.length > 0 ? (
                        <div className="col col-9 row">
                          {totalQuestions.easy?.easySubTopics?.length > 0 &&
                            totalQuestions.easy.easySubTopics.map(
                              (topic, index) => {
                                const findObject = subTopicQuestion.filter(
                                  (item) => Object.keys(item)[0] === "easy"
                                )[0];
                                return (
                                  <div className="col col-3">
                                    <label
                                      htmlFor="easyQuestion"
                                      className="form-label"
                                    >
                                      {Object.keys(topic)[0]}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="easyQuestion"
                                      name={Object.keys(topic)[0]}
                                      value={findObject.easy[Object.keys(topic)[0]]}
                                      onChange={(e) => {
                                        const names = e.target.name;
                                        const temp = [...subTopicQuestion];
                                        let totalSum = 0;
                                        const getTopic = temp.filter(
                                          (item) => Object.keys(item)[0] === "easy"
                                        )[0];
                                        const findNumber =
                                          totalQuestions.easy.easySubTopics.find(
                                            (item) => Object.keys(item)[0] === names
                                          );
                                        const topics = getTopic.easy;
                                        for (let name in topics) {
                                          if (name !== names) {
                                            totalSum += parseInt(topics[name]);
                                          }
                                        }
                                        setCreateExamSchedule({
                                          ...createExamSchedule,
                                          totalQuestion:
                                            totalSum + parseInt(e.target.value) <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(e.target.value) <=
                                              parseInt(
                                                Object.values(findNumber)[0]
                                              ) &&
                                              parseInt(e.target.value) <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(e.target.value) <=
                                              parseInt(
                                                totalQuestions.easy
                                                  .easyQuestionLength
                                              ) &&
                                              parseInt(e.target.value) <=
                                              parseInt(e.target.value) +
                                              parseInt(
                                                createExamSchedule.medium
                                              ) +
                                              parseInt(createExamSchedule.hard)
                                              ? parseInt(e.target.value) +
                                              parseInt(totalSum) +
                                              parseInt(
                                                createExamSchedule.medium
                                              ) +
                                              parseInt(createExamSchedule.hard)
                                              : totalSum +
                                              parseInt(
                                                createExamSchedule.medium
                                              ) +
                                              parseInt(createExamSchedule.hard),
                                          easy:
                                            totalSum + parseInt(e.target.value) <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(createExamSchedule.medium) +
                                              totalSum +
                                              parseInt(createExamSchedule.hard) +
                                              parseInt(e.target.value) <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(e.target.value) <=
                                              parseInt(Object.values(findNumber)[0])
                                              ? totalSum + parseInt(e.target.value)
                                              : totalSum,
                                        });

                                        getTopic.easy[names] =
                                          parseInt(e.target.value) <=
                                            parseInt(
                                              Object.values(findNumber)[0]
                                            ) &&
                                            parseInt(e.target.value) +
                                            totalSum +
                                            parseInt(createExamSchedule.medium) +
                                            parseInt(createExamSchedule.hard) <=
                                            parseInt(totalQuestions.length) &&
                                            parseInt(createExamSchedule.easy) +
                                            parseInt(createExamSchedule.medium) +
                                            parseInt(createExamSchedule.hard) <=
                                            parseInt(totalQuestions.length)
                                            ? parseInt(e.target.value)
                                            : 0;

                                        setSubTopicQuestion(temp);
                                      }}
                                    />
                                    <p className="text-success">
                                      AQ:{Object.values(topic)[0]}
                                    </p>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      ) : (
                        totalQuestions.medium?.mediumSubTopics?.length > 0 && (
                          <div className="col col-9 row"></div>
                        )
                      )}
                      <div className="col col-3">
                        <label htmlFor="mediumQuestion" className="form-label">
                          Medium
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mediumQuestion"
                          value={createExamSchedule.medium}
                          disabled={
                            totalQuestions.medium?.mediumSubTopics?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            setCreateExamSchedule({
                              ...createExamSchedule,
                              totalQuestion:
                                parseInt(e.target.value) <=
                                  parseInt(totalQuestions.length) &&
                                  parseInt(e.target.value) <=
                                  parseInt(
                                    totalQuestions.medium.mediumQuestionLength
                                  ) &&
                                  parseInt(e.target.value) <=
                                  parseInt(e.target.value) +
                                  parseInt(createExamSchedule.easy) +
                                  parseInt(createExamSchedule.hard)
                                  ? parseInt(e.target.value) +
                                  parseInt(createExamSchedule.easy) +
                                  parseInt(createExamSchedule.hard)
                                  : parseInt(createExamSchedule.easy) +
                                  parseInt(createExamSchedule.hard),
                              medium:
                                parseInt(e.target.value) <=
                                  parseInt(
                                    totalQuestions.medium.mediumQuestionLength
                                  ) &&
                                  parseInt(createExamSchedule.easy) +
                                  parseInt(e.target.value) +
                                  parseInt(createExamSchedule.hard) <=
                                  parseInt(totalQuestions.length)
                                  ? parseInt(e.target.value)
                                  : 0,
                            })
                          }
                        />
                        <p className="text-success">
                          AQ:
                          {totalQuestions.medium?.mediumQuestionLength
                            ? totalQuestions.medium?.mediumQuestionLength
                            : 0}
                        </p>
                      </div>
                      {totalQuestions.medium?.mediumSubTopics?.length > 0 ? (
                        <div className="col col-9 row">
                          {totalQuestions.medium?.mediumSubTopics?.length > 0 &&
                            totalQuestions.medium.mediumSubTopics.map(
                              (topic, index) => {
                                const findObject = subTopicQuestion.filter(
                                  (item) => Object.keys(item)[0] === "medium"
                                )[0];
                                return (
                                  <div className="col col-3">
                                    <label
                                      htmlFor="easyQuestion"
                                      className="form-label"
                                    >
                                      {Object.keys(topic)[0]}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="mediumQuestion"
                                      name={Object.keys(topic)[0]}
                                      value={
                                        findObject.medium[Object.keys(topic)[0]]
                                      }
                                      onChange={(e) => {
                                        const names = e.target.name;
                                        const temp = [...subTopicQuestion];
                                        let totalSum = 0;
                                        const getTopic = temp.filter(
                                          (item) =>
                                            Object.keys(item)[0] === "medium"
                                        )[0];
                                        const findNumber =
                                          totalQuestions.medium.mediumSubTopics.find(
                                            (item) => Object.keys(item)[0] === names
                                          );
                                        const topics = getTopic.medium;
                                        for (let name in topics) {
                                          if (name !== names) {
                                            totalSum += parseInt(topics[name]);
                                          }
                                        }
                                        setCreateExamSchedule({
                                          ...createExamSchedule,
                                          totalQuestion:
                                            totalSum + parseInt(e.target.value) <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(e.target.value) <=
                                              parseInt(
                                                Object.values(findNumber)[0]
                                              ) &&
                                              parseInt(e.target.value) <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(e.target.value) <=
                                              parseInt(
                                                totalQuestions.medium
                                                  .mediumQuestionLength
                                              ) &&
                                              parseInt(e.target.value) <=
                                              parseInt(e.target.value) +
                                              parseInt(createExamSchedule.easy) +
                                              parseInt(createExamSchedule.hard)
                                              ? parseInt(e.target.value) +
                                              parseInt(totalSum) +
                                              parseInt(createExamSchedule.easy) +
                                              parseInt(createExamSchedule.hard)
                                              : totalSum +
                                              parseInt(createExamSchedule.easy) +
                                              parseInt(createExamSchedule.hard),
                                          medium:
                                            totalSum + parseInt(e.target.value) <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(createExamSchedule.easy) +
                                              parseInt(createExamSchedule.hard) +
                                              parseInt(e.target.value) +
                                              totalSum <=
                                              parseInt(totalQuestions.length) &&
                                              parseInt(e.target.value) <=
                                              parseInt(Object.values(findNumber)[0])
                                              ? totalSum + parseInt(e.target.value)
                                              : totalSum,
                                        });
                                        getTopic.medium[names] =
                                          parseInt(e.target.value) <=
                                            parseInt(
                                              Object.values(findNumber)[0]
                                            ) &&
                                            parseInt(e.target.value) +
                                            totalSum +
                                            parseInt(createExamSchedule.easy) +
                                            parseInt(createExamSchedule.hard) <=
                                            parseInt(totalQuestions.length) &&
                                            parseInt(createExamSchedule.easy) +
                                            parseInt(createExamSchedule.medium) +
                                            parseInt(createExamSchedule.hard) <=
                                            parseInt(totalQuestions.length)
                                            ? parseInt(e.target.value)
                                            : 0;
                                        setSubTopicQuestion(temp);
                                      }}
                                    />
                                    <p className="text-success">
                                      AQ:{Object.values(topic)[0]}
                                    </p>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      ) : (
                        totalQuestions.hard?.hardSubTopics?.length > 0 && (
                          <div className="col col-9 row"></div>
                        )
                      )}
                      <div className="col col-3">
                        <label htmlFor="hardQuestion" className="form-label">
                          Hard
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="hardQuestion"
                          value={createExamSchedule.hard}
                          disabled={
                            totalQuestions.hard?.hardSubTopics?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setCreateExamSchedule({
                              ...createExamSchedule,
                              totalQuestion:
                                parseInt(e.target.value) <=
                                  parseInt(totalQuestions.length) &&
                                  parseInt(e.target.value) <=
                                  parseInt(
                                    totalQuestions.hard.hardQuestionLength
                                  ) &&
                                  parseInt(e.target.value) <=
                                  parseInt(e.target.value) +
                                  parseInt(createExamSchedule.easy) +
                                  parseInt(createExamSchedule.medium)
                                  ? parseInt(e.target.value) +
                                  parseInt(createExamSchedule.easy) +
                                  parseInt(createExamSchedule.medium)
                                  : parseInt(createExamSchedule.easy) +
                                  parseInt(createExamSchedule.medium),
                              hard:
                                parseInt(e.target.value) <=
                                  parseInt(
                                    totalQuestions.hard.hardQuestionLength
                                  ) &&
                                  parseInt(createExamSchedule.easy) +
                                  parseInt(createExamSchedule.medium) +
                                  parseInt(e.target.value) <=
                                  parseInt(totalQuestions.length)
                                  ? parseInt(e.target.value)
                                  : 0,
                            });
                          }}
                        />
                        <p className="text-success">
                          AQ:{" "}
                          {totalQuestions.hard?.hardQuestionLength
                            ? totalQuestions.hard?.hardQuestionLength
                            : 0}
                        </p>
                      </div>
                      {totalQuestions.hard?.hardSubTopics?.length > 0 && (
                        <div className="col col-9 row">
                          {totalQuestions.hard.hardSubTopics.map((topic, index) => {
                            const findObject = subTopicQuestion.filter(
                              (item) => Object.keys(item)[0] === "hard"
                            )[0];
                            return (
                              <div className="col col-3">
                                <label
                                  htmlFor="easyQuestion"
                                  className="form-label"
                                >
                                  {Object.keys(topic)[0]}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="mediumQuestion"
                                  name={Object.keys(topic)[0]}
                                  value={findObject.hard[Object.keys(topic)[0]]}
                                  onChange={(e) => {
                                    const names = e.target.name;
                                    const temp = [...subTopicQuestion];
                                    let totalSum = 0;
                                    const getTopic = temp.filter(
                                      (item) => Object.keys(item)[0] === "hard"
                                    )[0];
                                    const findNumber =
                                      totalQuestions.hard.hardSubTopics.find(
                                        (item) => Object.keys(item)[0] === names
                                      );
                                    const topics = getTopic.hard;
                                    for (let name in topics) {
                                      if (name !== names) {
                                        totalSum += parseInt(topics[name]);
                                      }
                                    }
                                    setCreateExamSchedule({
                                      ...createExamSchedule,
                                      totalQuestion:
                                        totalSum + parseInt(e.target.value) <=
                                          parseInt(totalQuestions.length) &&
                                          parseInt(e.target.value) <=
                                          parseInt(Object.values(findNumber)[0]) &&
                                          parseInt(e.target.value) <=
                                          parseInt(totalQuestions.length) &&
                                          parseInt(e.target.value) <=
                                          parseInt(
                                            totalQuestions.hard.hardQuestionLength
                                          ) &&
                                          parseInt(e.target.value) <=
                                          parseInt(e.target.value) +
                                          parseInt(createExamSchedule.easy) +
                                          parseInt(createExamSchedule.medium)
                                          ? parseInt(e.target.value) +
                                          parseInt(totalSum) +
                                          parseInt(createExamSchedule.easy) +
                                          parseInt(createExamSchedule.medium)
                                          : totalSum +
                                          parseInt(createExamSchedule.easy) +
                                          parseInt(createExamSchedule.medium),
                                      hard:
                                        totalSum + parseInt(e.target.value) <=
                                          parseInt(totalQuestions.length) &&
                                          parseInt(createExamSchedule.medium) +
                                          parseInt(createExamSchedule.easy) +
                                          totalSum +
                                          parseInt(e.target.value) <=
                                          parseInt(totalQuestions.length) &&
                                          parseInt(e.target.value) <=
                                          parseInt(Object.values(findNumber)[0])
                                          ? totalSum + parseInt(e.target.value)
                                          : totalSum,
                                    });
                                    getTopic.hard[names] =
                                      parseInt(e.target.value) <=
                                        parseInt(Object.values(findNumber)[0]) &&
                                        parseInt(e.target.value) +
                                        totalSum +
                                        parseInt(createExamSchedule.easy) +
                                        parseInt(createExamSchedule.medium) <=
                                        parseInt(totalQuestions.length) &&
                                        parseInt(createExamSchedule.easy) +
                                        parseInt(createExamSchedule.medium) +
                                        parseInt(createExamSchedule.hard) <=
                                        parseInt(totalQuestions.length)
                                        ? parseInt(e.target.value)
                                        : 0;
                                    setSubTopicQuestion(temp);
                                  }}
                                />
                                <p className="text-success">
                                  AQ:{Object.values(topic)[0]}
                                </p>
                              </div>
                            );
                          })}{" "}
                        </div>
                      )}
                    </div>
                  </>
                ))
              )
            }
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 position-relative">
              <label htmlFor="scname" className="form-label">
                Create Start Date and Time
              </label>
              <input
                type="datetime-local"
                className="form-control"
                id="scname"
                value={createExamSchedule.examDate}
                onChange={(e) =>
                  setCreateExamSchedule({
                    ...createExamSchedule,
                    examDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 position-relative">
              <label htmlFor="scname2" className="form-label">
                Create End Date and Time
              </label>
              <input
                type="datetime-local"
                className="form-control"
                id="scname2"
                value={createExamSchedule.endDate}
                onChange={(e) =>
                  setCreateExamSchedule({
                    ...createExamSchedule,
                    endDate: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-4 col-sm-4 col-md-4 col-lg-4 mt-2 position-relative">
              <label htmlFor="scname" className="form-label me-3">
                Send Email ? :
              </label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={createExamSchedule.sendEmail === "Yes"}
                  value="Yes"
                  id="yes"
                  onClick={() =>
                    setCreateExamSchedule({
                      ...createExamSchedule,
                      sendEmail: "Yes",
                    })
                  }
                />
                <label className="form-check-label" htmlFor="yes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={createExamSchedule.sendEmail === "No"}
                  value="No"
                  id="no"
                  onClick={() =>
                    setCreateExamSchedule({
                      ...createExamSchedule,
                      sendEmail: "No",
                    })
                  }
                />
                <label className="form-check-label" htmlFor="no">
                  No
                </label>
              </div>
            </div>

            {/* {createExamSchedule.examType === "Practical" && (
              <div className="col-4 col-sm-4 col-md-4 col-lg-4 mt-2 position-relative">
                <label htmlFor="scname" className="form-label me-3">
                  Mark As Completed ? :
                </label>

                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={createExamSchedule.markAsCompleted === true}
                  onClick={(e) => handleCheckbox(e)}
                />
              </div>
            )} */}

            <div className="ms-0">
              <button
                type="submit"
                className="btn btn-submit btn-success w-25 mt-3 ms-2"
                onClick={updateData === "" ? handleSubmit : handleEditSubmit}
              // onClick={scrollToTop}
              // style={{ display: visible ? "inline" : "none" }}
              >
                {updateData === ""
                  ? "Create Exam Schedule"
                  : "Update Exam Schedule"}
              </button>
              {updateData !== "" ? (
                <button
                  type="button"
                  className="btn btn-submit btn-secondary w-25 mt-3 ms-2"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setTotalQuestions({
                      length: 0,
                      easy: {},
                      medium: {},
                      hard: {},
                      status: false,
                    });
                    localStorage.removeItem("subTopicList");
                    setUpdateData("");
                    setCreateExamSchedule(initialExamSchedule);
                    setMultiSkill(false);
                    setSelectedSubSkills([]);
                    setUserSkills([]);
                    setSelectedUserSubSkills({});
                  }}
                // style={{ display: visible ? "inline" : "none" }}
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-submit btn-secondary w-25 mt-3 ms-2"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setTotalQuestions({
                      length: 0,
                      easy: {},
                      medium: {},
                      hard: {},
                      status: false,
                    });
                    setUpdateData("");
                    setCreateExamSchedule(initialExamSchedule);
                    setSubTopicQuestion([]);
                    setUserSkills([]);
                    setTotalTimeValidation([]);
                    setPickQuestionValidation([]);
                    setSelectedSubSkills([]);
                  }}
                // style={{ display: visible ? "inline" : "none" }}
                >
                  Reset
                </button>
              )}
            </div>
          </form>
        </div>

        <div className="listing-data table-respons">
          {trainerRequest && trainerRequest.length > 0 && (
            <>
              <h4 className="mt-2 text-center">All Created Request</h4>
              <table className="table mt-4
              ">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Trainer Name</th>
                    <th scope="col">Exam Code</th>
                    <th scope="col">Batch Name</th>
                    <th scope="col">Type Of Exam</th>
                    <th scope="col">Total Duration</th>
                    <th scope="col">Total Questions</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Exam Type</th>
                    <th scope="col">send Email</th>
                    <th scope="col">Mark AS Completed</th>
                    <th scope="col">base64</th>
                    <th scope="col">CreatedBy</th>
                    <th scope="col">UpdatedBy</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  {trainerRequest.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.trainerId?.username}</td>
                        <td>{item.examId}</td>
                        {item.batchId && <td>{item.batchId?.name}</td>}
                        <td>{item.typeOfExam}</td>
                        {item.totalTime ? (
                          <td className="text-center">{item.totalTime}</td>
                        ) : (
                          <td></td>
                        )}
                        <td className="text-center">{item.totalQuestion}</td>
                        <td>{DateFormat(item.examDate)}</td>
                        <td>{DateFormat(item.endDate)}</td>
                        <td>{item.examType}</td>
                        <td className="text-center">{item.sendEmail}</td>
                        {item.markAsCompleted === false &&
                          item.examType === "Practical" ? (
                          <td className="text-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkbox"
                              onClick={(e) => handleTableCheckbox(e, item)}
                            />
                          </td>
                        ) : item.markAsCompleted === true &&
                          item.examType === "Practical" ? (
                          <td className="text-center">
                            <i className="fa-solid fa-code-merge fs-4"></i>
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>
                          {" "}
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            onClick={() => {
                              BindModal(item.totalUsers, item.examId);
                            }}
                            data-bs-target="#exampleModal"
                          >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </a>
                        </td>
                        <td>
                          {handlecreatedAndUpdateby(item.createdBy)} {item.createdAt ? `on ${handleCreateUpadateDate(item.createdAt)}` : ''}
                        </td>
                        <td>
                          {handlecreatedAndUpdateby(item.updatedBy) && item.updatedAt //} {item.updatedAt && item.updatedBy
                            ? `${handlecreatedAndUpdateby(item.updatedBy)} on ${handleCreateUpadateDate(item.updatedAt)}` 
                            : ''}
                        </td>                        
                        <td>
                          <div className="d-flex">
                            <div>
                              <button
                                className="btn btn-warning px-2 py-1 mx-1 fs-8"
                                onClick={async (e) => {
                                  localStorage.setItem(
                                    "subTopicList",
                                    JSON.stringify(item.skillTopicName)
                                  );
                                  // getTotalQuestions(item.userSkill[0])
                                  setUpdateData("update");
                                  setCreateExamId(item.examId);
                                  // let users = [];
                                  // const userIdAdd = item.totalUsers.filter(
                                  //   (item) => users.push(item._id)
                                  // );
                                  const users = item.totalUsers.map((user) => {
                                    return user._id
                                  })
                                  let date = new Date(item.examDate);
                                  let temp = `${date.getFullYear()}-${date.getMonth() + 1 > 9
                                    ? date.getMonth() + 1
                                    : `0${date.getMonth() + 1}`
                                    }-${date.getDate() > 9
                                      ? date.getDate()
                                      : `0${date.getDate()}`
                                    }T${date.getHours() > 9
                                      ? date.getHours()
                                      : `0${date.getHours()}`
                                    }:${date.getMinutes() > 9
                                      ? date.getMinutes()
                                      : `0${date.getMinutes()}`
                                    }`;
                                  let date1 = new Date(item.endDate);
                                  let temp1 = `${date1.getFullYear()}-${date1.getMonth() + 1 > 9
                                    ? date1.getMonth() + 1
                                    : `0${date1.getMonth() + 1}`
                                    }-${date1.getDate() > 9
                                      ? date1.getDate()
                                      : `0${date1.getDate()}`
                                    }T${date1.getHours() > 9
                                      ? date1.getHours()
                                      : `0${date1.getHours()}`
                                    }:${date1.getMinutes() > 9
                                      ? date1.getMinutes()
                                      : `0${date1.getMinutes()}`
                                    }`;
                                  setCreateExamSchedule({
                                    ...createExamSchedule,
                                    skillId: {
                                      value: item.skillId._id,
                                      label: item.skillId.name,
                                    },
                                    batchId: {
                                      value: item.batchId._id,
                                      label: item.batchId.name,
                                    },
                                    typeOfExam: {
                                      value: item.typeOfExam,
                                      label: item.typeOfExam,
                                    },
                                    totalQuestion: item.totalQuestion,
                                    totalTime: item.totalTime,
                                    examDate: temp,
                                    endDate: temp1,
                                    sendEmail: item.sendEmail,
                                    totalUsers: users,
                                    examType: item.examType,
                                    markAsCompleted: item.markAsCompleted,
                                    easy: item.easy,
                                    medium: item.medium,
                                    hard: item.hard,
                                    totalEasy: item.totalEasy,
                                    totalMedium: item.totalMedium,
                                    totalHard: item.totalHard,
                                  });
                                  if (
                                    item.userSkills &&
                                    item.userSkills.length > 0
                                  ) {
                                    setMultiSkill(true);
                                    const finalObject = {
                                      userSkill: item.userSkills,
                                      examType: item.examType,
                                      typeofExam: item.typeOfExam,
                                      subSkills: item.subSkills,
                                    };
                                    const temp = [];
                                    const userSkills = item.userSkills.forEach(
                                      (i) => {
                                        const skillExists = temp.find(
                                          (s) => s.skill === i.skill._id
                                        );
                                        if (skillExists) {
                                          skillExists.users.push(i.user._id);
                                        } else {
                                          temp.push({
                                            ...i,
                                            users: [i.user._id],
                                            skill: i.skill._id,
                                            skillname: i.skill.name,
                                            totalQuestions:
                                              i.totalQuestions || 0,
                                            pickQuestions: i.pickQuestions || 0,
                                            totalTimes: i.totalTimes || 0,
                                            easy: i.easy,
                                            medium: i.medium,
                                            hard: i.hard,
                                            totalEasy: i.totalEasy,
                                            totalMedium: i.totalMedium,
                                            totalHard: i.totalHard,
                                            subSkillTopicName:
                                              i.subSkillTopicName,
                                          });
                                        }
                                        // return null;
                                      }
                                    );
                                    setUserSkills(temp);
                                    getTotalQuestions(finalObject, false);
                                    //
                                    // const data = await
                                    // if (
                                    //   item.userSkills &&
                                    //   temp &&
                                    //   item.userSkills.length === temp.length
                                    // ) {
                                    // }
                                    const selectedSubSkills = temp.map((i) => {
                                      return {
                                        _id: i.skill,
                                        value: i.skillname,
                                        label: i.skillname,
                                      };
                                    });
                                    setSelectedSubSkills(selectedSubSkills);
                                    setSelectedUserSubSkills({});
                                  } else {
                                    setMultiSkill(false);
                                    setSelectedSubSkills([]);
                                    setUserSkills([]);
                                    setSelectedUserSubSkills({});
                                  }
                                }}
                              >
                                <FaPen className="color-white text-white" />
                              </button>
                            </div>
                            <div>
                              <button
                                className="btn btn-outline-danger px-2 py-1 mx-1 fs-8"
                                onClick={(e) => handleDeleteExam(item._id)}
                              >
                                <RiDeleteBin3Line />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/*  */}
            </>
          )}
          <div
            class="modal fade "
            style={{ width: "1500px" }}
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" style={{ width: "1200px" }}>
              <div class="modal-content" style={{ width: "1000px" }}>
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Exams
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div>
                    <table className="table" id="table"></table>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5 py-4 d-flex justify-content-end">
          {buttonStatus && <button type="button" className="border btn btn-secondry" onClick={() => {
            setPageNumber(pageNumber + 1);
            getExamSchedule(pageNumber + 1)
          }}>Load More</button>}
        </div>
      </div>
    </>
  );
}
