import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./courseTab.scss";
import $ from "jquery";

export default function CourseTab(props) {
  const param = useParams();
  const {
    videos,
    course,
    percentages,
    lastvideos,
    sidelg,
    setSideSM,
    sidesm,
    setSideLG,
    videoQuality,
    setVidoQuality,
    shortNotes,
  } = props;
  const isShortNotes = shortNotes && shortNotes.length > 0 && shortNotes.filter(shortNote => !['', 'false', null].includes(shortNote?.shortNotes)).length > 0;
  const currentModule = window?.location?.href.split("#")[1] && window.location.href.split("#")[1] || ''
  
  const navigate = useNavigate();
  // if (!(videos.length > 0)) {
  //   $(".tab-contents").addClass("mx-auto");
  // } else {
  //   $(".tab-contents").removeClass("mx-auto");
  // }
  $(document).ready(function () {
    footerShow();
    $(window).on("resize", function () {
      footerShow();
    });
  });
  function footerShow() {
    var screenWidth = $(document).width();
    if (screenWidth < 1199) {
      $("footer").css({ "margin-bottom": $(".footer-sm").height() });
      $(".footer-sm").css("bottom", "0px");
      $(window).scroll(function () {
        let d = document.documentElement;
        let offset = d.scrollTop + window.innerHeight;
        let height = d.offsetHeight;
        if (offset >= height) {
        } else {
          $(".footer-sm").css("bottom", "0px");
        }
      });
    } else {
      $(".footer-sm").fadeOut();
      $("footer").css({ "margin-bottom": "0px" });
    }
  }
  return (
    <>
      <div class="">
        <div class="pt-lg-3 align-items-center tutorial-banner-content">
          <div class="back-link">
            <a
              href={`/course/${param.cname}`}
              class="text-decoration-none text-primary">
              <img
                width="16"
                height="9"
                class="me-2"
                src={
                  require("../../assets/images/Iconmaterial-keyboard-arrow-down.svg")
                    .default
                }
              />
              Back
            </a>
          </div>
          <div class="align-items-center justify-content-between toggle-wrapper-outer">
            <div class="d-flex py-1 toggle-wrapper align-items-center">
              {videos &&
                videos.length > 0 &&
                param.choice !== "tplan" &&
                param.choice !== "snotes" &&
                param.choice !== "additional-resources" &&
                param.choice !== "discussion" && (
                  <button
                    class="btn toggle-sidebar pe-0 me-1"
                    onClick={() => setSideSM(true)}>
                    <img
                      width="24"
                      height="18"
                      alt="bar btn"
                      src={require("../../assets/images/bar-btn.svg").default}
                    />
                  </button>
                )}
              {course &&
                course.modules &&
                course.modules.length > 0 &&
                param.choice !== "videos" &&
                param.choice !== "additional-resources" &&
                param.choice !== "discussion" && (
                  <button
                    class="btn toggle-sidebar pe-0 me-1"
                    onClick={() => setSideSM(true)}>
                    <img
                      width="24"
                      height="18"
                      alt="bar btn"
                      src={require("../../assets/images/bar-btn.svg").default}
                    />
                  </button>
                )}

              <h2
                class="m-0 px-3 text-truncate"
                style={{ maxWidth: "420px" }}
                title={param.sname.replaceAll("_", " ")}>
                {param.sname.replaceAll("_", " ")}
              </h2>
            </div>
            {window.location.href?.split("/")?.length > 0 &&
              window.location.href?.split("/")?.[7] === "videos" &&
              window.location.href.split("/")?.[8]?.includes("new") && (
                <div className="d-flex justify-content-end">
                  <div>
                    {/* <label htmlFor="difficulty" className="form-label">	
Difficulty	
</label> */}
                    <select
                      className="form-select"
                      id="difficulty"
                      value={videoQuality}
                      name="difficulty"
                      onChange={
                        (e) => setVidoQuality(e.target.value)
                        // setPractical((d) => ({ ...d, difficulty: e.target.value }))
                      }>
                      <option value=".m3u8">Select video quality</option>
                      <option value="240_out.m3u8">240px</option>
                      <option value="360_out.m3u8">360px</option>
                      <option value="480_out.m3u8">480px</option>
                      <option value="720_out.m3u8">720px</option>
                      <option value="1080_out.m3u8">1080px</option>
                    </select>
                  </div>
                </div>
              )}
            <ul class="nav nav-pills mt-2 course-tab d-none d-xl-flex" id="myTab" role="tablist">
              <li class=" nav-item  " role="presentation">
                <button
                  class={` nav-link ${
                    param.choice === "tplan" ? "active " : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() =>
                    navigate(
                      `/course/${param?.cname}/courseOverview/${param?.sname}/tplan${currentModule > 1 ? '#'+currentModule : ''}`,
                      { state: { ...lastvideos } }
                    )
                  }>
                  Training Plan
                </button>
              </li>
              {
                isShortNotes && (
                  <li class="nav-item " role="presentation">
                    <button
                      class={`nav-link ${
                        param.choice === "snotes" ? "active " : ""
                      } `}
                      id=""
                      data-bs-toggle="tab"
                      data-bs-target="#"
                      type="button"
                      role="tab"
                      aria-selected="true"
                      onClick={() =>
                        navigate(
                          `/course/${param.cname}/courseOverview/${param.sname}/snotes${currentModule > 1 ? '#'+currentModule : ''}`,
                          { state: { ...lastvideos } }
                        )
                      }>
                      Short Notes
                    </button>
                  </li>
                )
              }
              <li class=" nav-item " role="presentation">
                <button
                  class={`nav-link ${
                    param.choice === "videos" ? "active " : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="true"
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/videos`,
                      {
                        state: {
                          videoss: videos,
                          percentages: percentages,
                          // sidesm: sidesm,
                          // // sidelg: sidelg,
                          // // setSideLG: setSideLG,
                          // // setSideSM: setSideSM,
                        },
                      }
                    )
                  }>
                  Video Tutorial
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class={`nav-link ${
                    param.choice === "additional-resources" ? "active" : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/additional-resources`,
                      { state: { ...lastvideos } }
                    )
                  }>
                  Additional Resources
                </button>
              </li>
              <li class="nav-item " role="presentation">
                <button
                  class={`nav-link ${
                    param.choice === "discussion" ? "active" : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/discussion`,
                      { state: { ...lastvideos } }
                    )
                  }>
                  Discussion
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="d-xl-none d-block py-3">
        <ul className="justify-content-end nav nav-pills mt-2 course-tab" id="myTab" role="tablist">
              <li class=" nav-item  " role="presentation">
                <button
                  class={` nav-link ${
                    param.choice === "tplan" ? "active " : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() =>
                    navigate(
                      `/course/${param?.cname}/courseOverview/${param?.sname}/tplan`,
                      { state: { ...lastvideos } }
                    )
                  }>
                  Training Plan
                </button>
              </li>
              {
                isShortNotes && (
                  <li class="nav-item " role="presentation">
                    <button
                      class={`nav-link ${
                        param.choice === "snotes" ? "active " : ""
                      } `}
                      id=""
                      data-bs-toggle="tab"
                      data-bs-target="#"
                      type="button"
                      role="tab"
                      aria-selected="true"
                      onClick={() =>
                        navigate(
                          `/course/${param.cname}/courseOverview/${param.sname}/snotes`,
                          { state: { ...lastvideos } }
                        )
                      }>
                      Short Notes
                    </button>
                  </li>
                )
              }
              <li class=" nav-item " role="presentation">
                <button
                  class={`nav-link ${
                    param.choice === "videos" ? "active " : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="true"
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/videos`,
                      {
                        state: {
                          videoss: videos,
                          percentages: percentages,
                          // sidesm: sidesm,
                          // // sidelg: sidelg,
                          // // setSideLG: setSideLG,
                          // // setSideSM: setSideSM,
                        },
                      }
                    )
                  }>
                  Video Tutorial
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class={`nav-link ${
                    param.choice === "additional-resources" ? "active" : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/additional-resources`,
                      { state: { ...lastvideos } }
                    )
                  }>
                  Additional Resources
                </button>
              </li>
              <li class="nav-item " role="presentation">
                <button
                  class={`nav-link ${
                    param.choice === "discussion" ? "active" : ""
                  } `}
                  id=""
                  data-bs-toggle="tab"
                  data-bs-target="#"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/discussion`,
                      { state: { ...lastvideos } }
                    )
                  }>
                  Discussion
                </button>
              </li>
            </ul>
        </div>
        <div class="footer-sm bg-primary text-white position-fixed left-0 w-100 p-0 tutorial-footer">
          <div class="container">
            <div class="row justify-content-around">
              <div class="col-3 d-flex align-items-center justify-content-center p-0">
                <a
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/tplan`,
                      { state: { ...lastvideos } }
                    )
                  }
                  title="Login"
                  // class="text-white text-decoration-none active-link"
                  class={`text-white text-decoration-none ${
                    param.choice === "tplan" ? "active-link " : ""
                  } `}>
                  <span>Training Plan</span>
                </a>
              </div>
              {
                isShortNotes && (
                  <div class="col-3 d-flex align-items-center justify-content-center p-0">
                    <a
                      onClick={() =>
                        navigate(
                          `/course/${param.cname}/courseOverview/${param.sname}/snotes`,
                          { state: { ...lastvideos } }
                        )
                      }
                      class={`text-white text-decoration-none ${
                        param.choice === "snotes" ? "active-link " : ""
                      } `}>
                      <span>Short Notes</span>
                    </a>
                  </div>
                )
              }
              <div class="col-3 d-flex align-items-center justify-content-center p-0">
                <a
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/videos`,
                      {
                        videoss: videos,
                        percentages: percentages,
                        // sidesm: sidesm,
                        // // sidelg: sidelg,
                        // // setSideLG: setSideLG,
                        // // setSideSM: setSideSM,
                      }
                    )
                  }
                  class={`text-white text-decoration-none ${
                    param.choice === "videos" ? "active-link " : ""
                  } `}>
                  <span>Video Tutorial</span>
                </a>
              </div>
              <div class="col-3 d-flex align-items-center justify-content-center p-0">
                <a
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/additional-resources`,
                      { state: { ...lastvideos } }
                    )
                  }
                  title="Sign Up"
                  // class="text-white text-decoration-none"
                  class={`text-white text-decoration-none ${
                    param.choice === "additional-resources"
                      ? "active-link "
                      : ""
                  } `}>
                  <span>Resources</span>
                </a>
              </div>
              <div class="col-3 d-flex align-items-center justify-content-center p-0">
                <a
                  onClick={() =>
                    navigate(
                      `/course/${param.cname}/courseOverview/${param.sname}/discussion`,
                      { state: { ...lastvideos } }
                    )
                  }
                  title="Sign Up"
                  // class="text-white text-decoration-none"
                  class={`text-white text-decoration-none ${
                    param.choice === "discussion" ? "active-link " : ""
                  } `}>
                  <span>Discussion</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
