import React, { useState, useEffect } from "react";
import "./TrainingPlan.scss";
import jwt_decode from "jwt-decode";
import $ from "jquery";
import CourseService from "../../services/CourseService";
import datanotfoundimage from "../../assets/images/DataNotFound-image.png";
import formloader from "../../images/formloading.svg";
import { useNavigate } from "react-router-dom";
import "./ShortNotes.scss";
import MarkdownIt from "markdown-it";

const md = new MarkdownIt();
export default function TrainingPlan(props) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [sidelg, setSideLG] = useState(true);
  const [sidesm, setSideSM] = useState(false);
  const [progress, setprogress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ msg: "", status: false });
  useEffect(() => {
    $(window).scrollTop(0);
  }, [window.location.href]);
  useEffect(() => {
    setLoading(true);
    CourseService.getTrainingProgress(
      jwt_decode(token).id,
      props.course.modules && props.course.modules[0].cid,
      token
    )
      .then((res) => {
        setprogress(res.data.progress.completedTrainingDayPlan);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [props]);

  useEffect(() => {
    if(!['varsha.oberoi@radixweb.com','ashok.prajapati@radixweb.com'].includes(jwt_decode(token).email) && jwt_decode(token).role !== "Admin"){
      localStorage.removeItem("token");
      navigate("/login");
    }
  }, [])

  const handleCompletedModule = (tplanDay) => {
    if (
      jwt_decode(token).role === "Admin" ||
      jwt_decode(token).role === "Trainer"
    ) {
      return navigate(`#${tplanDay}`);
    }
    if (progress?.length >= parseInt(tplanDay - 1)) {
      navigate(`#${tplanDay}`);

      return true;
    } else {
      setConfirmBox(true);
      setMsg({
        msg: "your current module marks as completed not done",
      });
      setTimeout(() => setConfirmBox(false), 5000);
      return false;
    }
  };

  $(document).ready(function () {
    $("a.scrollLink").click(function () {
      setTimeout(function () {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          0.0001
        );
      }, 0);
    });
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;
    if ($(window).width() > 1025) {
      $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
      $(".video-tutorial").css(
        "height",
        "calc(99vh - " + totalHeight + "px - 50px"
      );
      sidebarlg();
      videoPosition();
      setSideSM(false);
    } else {
      sidebarsm();
      setSideLG(false);
    }
    $(window).scroll(function (e) {
      topbarSticky();
      sideBarSticky();
    });
    $(window).resize(function (e) {
      if ($(window).width() > 1025) {
        $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
        $(".video-tutorial").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 50px"
        );
        $(".sidebar-wrapper").css({
          top: $("header").height() + $(".tutorial-banner").height() + 20,
        });
        $(".sidebar-wrapper").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 90px"
        );
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
        sidebarlg();
        videoPosition();
        setSideSM(false);
        setSideLG(true);
      } else {
        sidebarsm();
        setSideLG(false);
      }
      $(window).scroll(function (e) {
        topbarSticky();
        sideBarSticky();
      });
    });
    $(".sidebar-wrapper").css({
      top: $("header").height() + $(".tutorial-banner").height() + 50,
    });
  });
  function sidebarlg() {
    $(".collapse-btn").click(function () {
      setSideLG(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").addClass("mx-auto");
    });
    $(".slider-right-btn").click(function () {
      setSideLG(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $(".sidebar-wrapper").addClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").removeClass("mx-auto");
    });
  }
  function videoPosition() {
    $(window).scroll(function (e) {
      if ($(this).scrollTop() > 0) {
        if ($(this).scrollTop() > $(".video-tutorial").height() / 1.5) {
          $(".video-tutorial").addClass("video-fixed");
          $(".video-tutorial").css({ height: "17.25rem", width: "30.625rem" });
        }
      } else {
        $(".video-tutorial").removeClass("video-fixed");
      }
    });
  }
  function sidebarsm() {
    $(".smsidebar").css("height", "99vh");
    $(".sidebar-wrapper").addClass("collapse-sidebar");
    $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
    $(".toggle-sidebar").click(function (e) {
      setSideSM(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $("body").addClass("overflow-hidden");
      $(".sidebar-bg").removeClass("d-none");
    });
    $(".close-sidebar").click(function (e) {
      setSideSM(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $("body").removeClass("overflow-hidden");
      $(".sidebar-bg").addClass("d-none");
    });
  }
  function topbarSticky() {
    var $el = $(".tutorial-banner");
    if ($(window).scrollTop() > 50) {
      $("header").css({ height: "0px", overflow: "hidden" });
      $el.css({ top: "0px" });
      $el.addClass("position-sticky");
    } else {
      $el.removeClass("position-sticky");
      $("header").css({ height: "", overflow: "" });
    }
  }
  function sideBarSticky() {
    if ($(window).scrollTop() > 0) {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    } else {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    }
  }

  const hidebar = () => {
    $(document).ready(function () {
      if ($(window).width() < 1025) {
        setSideSM(false);
        $(".sidebar-wrapper").addClass("collapse-sidebar");
        $("body").removeClass("overflow-hidden");
        $(".sidebar-bg").addClass("d-none");
      }
    });
  };

  if (props.course.modules) {
    return (
      <>
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}

        <div class="sidebar-bg d-none">
          <button
            type="button"
            class="btn-close btn-close-white close-sidebar me-0 d-xl-none d-block"
            aria-label="Close"
          ></button>
        </div>
        <div class="col-12 col-xl-3 col-lg-4 sidebar-wrapper">
          {sidelg == true && (
            <div class="sidebar px-4 pb-4 sidebar-module overflow-auto">
              <div class="accordion-content course-detail-accordion tutorial-content">
                {props.course &&
                  props.course.modules &&
                  props.course.modules
                    .sort((a, b) => a.tp_day - b.tp_day)
                    .map((item, index) => {
                      return (
                        <div class="accordion-item">
                          {" "}
                          <h3 class="accordion-header px-0">
                            <a
                              className={
                                window.location.href.split("#")[1]
                                  ? window.location.href.split("#")[1] ==
                                    item.tp_day
                                    ? "text-green accordion-button scrollLink"
                                    : "bg-white accordion-button scrollLink"
                                  : item.tp_day == 1
                                    ? "text-green accordion-button scrollLink"
                                    : "bg-white accordion-button scrollLink"
                              }
                              onClick={() => {
                                handleCompletedModule(item.tp_day);
                              }}
                              value={item.tp_day}
                            >
                              Module {item.tp_day}
                            </a>
                          </h3>
                        </div>
                      );
                    })}
              </div>
            </div>
          )}

          {sidesm == true && (
            <div class="sidebar px-0 pb-4 sidebar-module smsidebar">
              <div class="accordion-content course-detail-accordion tutorial-content">
                {props.course &&
                  props.course.modules &&
                  props.course.modules
                    .sort((a, b) => a.tp_day - b.tp_day)
                    .map((item, index) => {
                      return (
                        <div class="accordion-item">
                          {" "}
                          <h3 class="accordion-header px-0">
                            <div onClick={() => hidebar()}>
                              <a
                                className={
                                  window.location.href.split("#")[1]
                                    ? window.location.href.split("#")[1] ==
                                      item.tp_day
                                      ? "text-green accordion-button scrollLink"
                                      : "bg-white accordion-button scrollLink"
                                    : item.tp_day == 1
                                      ? "text-green accordion-button scrollLink"
                                      : "bg-white accordion-button scrollLink"
                                }
                                onClick={() => {
                                  handleCompletedModule(item.tp_day);
                                }}
                                value={item.tp_day}
                              >
                                Module {item.tp_day}
                              </a>
                            </div>
                          </h3>
                        </div>
                      );
                    })}
              </div>
            </div>
          )}
        </div>

        <div class="col-12 col-xl-9 col-lg-8 mb-lg-4 tab-contents sidebar-course-content">
          {confirmBox && (
            <div className="formloade">
              <div className="row text-center">
                <div className="col-6 w-100 confirmbox">
                  <div
                    className={`alert ${msg.status ? "alert-success" : "alert-danger"
                      }`}
                  >
                    {msg.msg}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.course.modules ? (
            <div class="text-dark-gray px-lg-0 px-xl-4 pt-4 pb-0 my-3">
              <div className="accordion-content course-detail-accordion tutorial-content">
                {props.course.modules &&
                  props.course.modules.map((plan, index) => {
                    return window.location.href &&
                      window.location.href.split("#")[1]
                      ? window.location.href.split("#")[1] == plan.tp_day && (
                        <div className="px-3" id={plan.tp_day}>
                          <h2 className="shortNoteHeading" id={"headingt" + (index + 1)}>
                            Module {plan.tp_day} 
                          </h2>
                          <div
                            id={"collapset" + (index + 1)}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body px-0 pb-5 mb-5 pb-lg-4 mb-lg-4">
                                {plan?.shortNotes && !['false', 'null', ''].includes(plan?.shortNotes) ? (
                                    <div
                                        className="shortnotes"
                                        dangerouslySetInnerHTML={{
                                            __html: md.render(plan.shortNotes),
                                        }}
                                    />
                                ): (
                                <div class="pt-lg-4 align-items-center tab-contents">
                                    <div class="text-center my-lg-5 my-3">
                                        <img
                                        src={datanotfoundimage}
                                        width="450px"
                                        className="datanotfoundimage"
                                        />
                                        <h3 class="mt-lg-4 mt-3">
                                        We currently don't have any Short Notes for this module
                                        </h3>
                                    </div>
                                </div>
                              )}

                              <div class="d-flex justify-content-between pt-3">
                                <a
                                  href={`#${plan.tp_day - 1}`}
                                  value={plan.tp_day}
                                  className={`${plan.tp_day > 1
                                    ? ""
                                    : "disabled-link scrollLink visually-hidden"
                                    }`}
                                >
                                  Previous
                                </a>{" "}
                                <br />
                                <a
                                  onClick={() => {
                                    handleCompletedModule(plan.tp_day + 1);
                                  }}
                                  value={plan.tp_day}
                                  className={`${props.course.modules.length == plan.tp_day
                                    ? "disabled-link scrollLink visually-hidden"
                                    : "scrollLink"
                                    } cursor-pointer`}
                                >
                                  Next
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : plan.tp_day == 1 && (
                        <div id={plan.tp_day}>
                          <h2 className="shortNoteHeading" id={"headingt" + (index + 1)}>
                            <div>Module {plan.tp_day}</div>
                          </h2>
                          <div
                            id={"collapset" + (index + 1)}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body px-0 pb-5 mb-5 pb-lg-4 mb-lg-4">
                              {plan?.shortNotes && !['false', 'null', ''].includes(plan?.shortNotes) ? (
                                <div
                                    className="shortnotes"
                                    dangerouslySetInnerHTML={{
                                    __html: md.render(plan.shortNotes),
                                    }}
                                />
                              ): (
                                <div class="pt-lg-4 align-items-center tab-contents">
                                    <div class="text-center my-lg-5 my-3">
                                        <img
                                        src={datanotfoundimage}
                                        width="450px"
                                        className="datanotfoundimage"
                                        />
                                        <h3 class="mt-lg-4 mt-3">
                                        We currently don't have any Short Notes for this course.
                                        </h3>
                                    </div>
                                </div>
                              )}
                              <div class="d-flex justify-content-between pt-3">
                                <a
                                  className={`${plan.tp_day > 1
                                    ? "scrollLink"
                                    : "disabled-link scrollLink hide"
                                    }`}
                                  value={plan.tp_day}
                                >
                                  Previous
                                </a>
                                <a
                                  href={`#${plan.tp_day + 1}`}
                                  value={plan.tp_day}
                                  className="scrollLink"
                                >
                                  Next
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                  })}{" "}
              </div>
            </div>
          ) : (
            <div class="pt-lg-4 align-items-center tab-contents">
              <div class="text-center my-lg-5 my-3">
                <img
                  src={datanotfoundimage}
                  width="450px"
                  className="datanotfoundimage"
                />
                <h3 class="mt-lg-4 mt-3">
                  We currently don't have any Short Notes for this course.
                </h3>
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div class="pt-lg-4 align-items-center tab-contents">
        <div class="text-center my-lg-5 my-3">
          <img
            src={datanotfoundimage}
            width="450px"
            className="datanotfoundimage"
          />
          <h3 class="mt-lg-4 mt-3">
            We currently don't have any Short Notes for this course.
          </h3>
        </div>
      </div>
    );
  }
}
