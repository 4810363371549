import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { FaPen, FaTimes, FaTrash } from "react-icons/fa";
import { BiCommentDetail, BiTimeFive } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import BatchInfoCss from "./batchInfo.scss";
import variable from "../../servicesvariable";
import Loader from '../../components/loader/Loader';
import HandleMessage from "../../components/HandleMessage/HandleMessages";
import BatchManagementServices from "../../services/BatchManagementServices";
import CourseServices from "../../services/CourseService";

export default function BatchInfo() {

    const { SkillName, ExamType, BatchId } = useParams();
    const navigate = useNavigate();
    let { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const [loader, setLoader] = useState(false)
    const [confirmBox, setConfirmBox] = useState(false)
    const [message, setMessage] = useState({ msg: "", status: false })
    const [batchData, setBatchData] = useState({})
    const [mcqsData, setMcqsData] = useState({
        mcqsDataMarks: [],
        userId: "",
        userName: "",
        mcqsExamCode: [],
        examType: ""
    });
    const [practicalExamData, setPracticalExamData] = useState({
        reviewData: [],
        hostId: "",
        userId: "",
        examType: "",
        buttonStatus: true,
    });
    const [practicalExamId, setPracticalExamId] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openMcqsMarksModel, setMcqsMarksModel] = useState(false);
    const [confirmbox, setConfirmbox] = useState(false);
    const [msg, setMsg] = useState({ message: "", status: false });
    const [markRemarkValidation, setMarkRemarkValidation] = useState([]);
    const [handleEditId, setHandleEditId] = useState("");
    const initialReview = {
        onDeadline: 0,
        deadline: 0,
        analytics: 0,
        database: 0,
        ui: 0,
        serverside: 0,
        remark: "",
    };
    const [reviewData, setReviewData] = useState({
        remark: "",
        onDeadline: ""
    });
    const [reviewDataPercentage, setReviewDataPercentage] = useState({
        deadlinePercentage: 10,
        analyticsPercentage: 30,
        databasePercentage: 20,
        uiPercentage: 20,
        serversidePercentage: 20,
        remark: "",
        onDeadline: ""
    })
    const currentUser = jwt_decode(localStorage.getItem("token"));

    useEffect(async () => {
        const token = localStorage.getItem("token");
        if (token) {
            if (queryParams.get("startDate") && queryParams.get("endDate") && (ExamType === 'internalExam' || ExamType === 'externalExam' || ExamType === 'externalMeeting')) {
                setLoader(true)
                const batchData = {
                    skillName: SkillName,
                    examType: ExamType,
                    startDate: queryParams.get("startDate"),
                    endDate: queryParams.get("endDate"),
                }
                await BatchManagementServices.getBatchInfoData(batchData, BatchId, token).then((res) => {
                    setLoader(false)
                    if (res.data?.users?.length > 0) {
                        let updatedData = res.data
                        updatedData.users = res.data?.users?.map(user => Object.keys(user).includes("IsExternalMarksAsInternal") ? {...user} : {...user, IsExternalMarksAsInternal: false})
                        setBatchData(updatedData)
                    } else {
                        handleMessage("Batch realted users are not found", false)
                    }
                }).catch((err) => {
                    handleCatchError(err)
                })
            }
            else {
                if (ExamType !== 'internalExam' || ExamType !== 'externalExam' || ExamType !== 'externalMeeting') {
                    handleMessage("Your exam type is not proper", false)
                } else {
                    handleMessage("Url to not found start date and end date query parameters", false)
                }
            }
        } else {
            navigate("/login");
        }
    }, [])

    const handleCatchError = (err) => {
        setLoader(false)
        if (err["response"]) {
            if (err["response"].status === 401) {
                if (err["response"]?.data?.message === "You are not authorized to view this content") {
                    handleMessage(err["response"].data.message, false)
                } else {
                    localStorage.removeItem("token");
                    navigate("/login");
                }
            } else if (err["response"].status === 404) {
                handleMessage(err["response"]?.data?.message, false)
            } else {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    }

    const handleMessage = (message, status, magtime = false) => {
        setConfirmBox(true)
        setMessage({ msg: message, status: status })
        if (magtime) {
            setTimeout(() => setConfirmBox(false), 3000)
        }
    }

    const getInternalExamMark = (examData, examType) => {
        let marks = 0
        if (examData[examType] && examData[examType]?.length > 0) {
            marks = examData[examType][examData[examType].length - 1]?.marks || 0
        }
        return marks
    }

    const findExamCode = (resultData, examCodeData) => {
        const practicalExamCode = examCodeData?.filter(examCode => examCode.examType === 'Practical')
        const examCodeIdArray = examCodeData.filter((examCode) => examCode.examId && examCode.examType === 'Practical').map((examCode) => examCode.examId),
            resultIdArray = resultData['practical']?.map((currResultData) => currResultData.examId);
        if (ExamType === 'externalMeeting'){
            const meetingCode = examCodeData?.filter(examCode => examCode.examType === 'Meeting');
            const examCodeIdArray = examCodeData.filter((examCode) => examCode.examId && (examCode.examType === 'Meeting')).map((examCode) => examCode.examId),
                resultIdArray = resultData['meeting']?.map((currResultData) => currResultData.examId);
            if (resultData['meeting']?.length > 0 && examCodeData?.length > 0) {
                if (examCodeIdArray.length <= resultIdArray.length) {
                    var finalResult = {
                        status: false,
                        examId: meetingCode[meetingCode.length - 1]?.examId
                    }
                } else {
                    var finalResult = {
                        status: true,
                        examId: meetingCode[meetingCode.length - 1]?.examId
                    }
                }
            }
            else{
                if (examCodeData?.length > 0) {
                    var finalResult = {
                        status: true,
                        examId: meetingCode[meetingCode.length - 1]?.examId
                    }
                } else {
                    var finalResult = {
                        status: false,
                        examId: "Not Created Exam"
                    }
                }
            }
        }
        else{
            examCodeData = examCodeData?.filter(examCode => examCode.examType === 'Practical')
            if (resultData['practical']?.length > 0 && examCodeData?.length > 0) {
                if (examCodeIdArray.length <= resultIdArray.length) {
                    var finalResult = {
                        status: false,
                        examId: practicalExamCode[practicalExamCode.length - 1]?.examId
                    }
                } else {
                    var finalResult = {
                        status: true,
                        examId: practicalExamCode[practicalExamCode.length - 1]?.examId
                    }
                }
            }
            else {
                if (examCodeData?.length > 0) {
                    var finalResult = {
                        status: true,
                        examId: practicalExamCode[practicalExamCode.length - 1]?.examId
                    }
                } else {
                    var finalResult = {
                        status: false,
                        examId: "Not Created Exam"
                    }
                }
            }
        }
        return finalResult
    }

    const handleChange = (e, userId) => {
        const { name, value } = e.target
        const oldBatchData = { ...batchData }
        const userData = batchData.users.find(user => user.userId === userId)
        switch (name) {
            case 'mark':
                if (parseInt(value) > 0 && parseInt(value) <= 100) {
                    userData.marks = parseInt(value)
                    setBatchData(oldBatchData)
                } else {
                    userData.marks = ""
                    setBatchData(oldBatchData)
                }
                break
            case 'remark':
                if (value?.length > 0) {
                    userData.remark = value
                    setBatchData(oldBatchData)
                } else {
                    userData.remark = ""
                    setBatchData(oldBatchData)
                }
                break
            case 'checkbox':
                if(e.target.checked){
                    userData.marks = Math.round(userData?.average) || 0;
                }
                else{
                    userData.marks = "";
                }
                userData.IsExternalMarksAsInternal = e.target.checked
                setBatchData(oldBatchData)
                break
        }
    }

    const setExamData = (userResult) => {
        if (userResult?._id) {
            const oldBatchData = { ...batchData }
            const userData = batchData.users.find(user => user.userId === userResult.userId)
            if(ExamType === 'externalMeeting'){
                userData['externalExam']['meeting'] = userResult['externalExam']['meeting']
            }
            else{
                userData[ExamType]['practical'] = userResult[ExamType]['practical']
            }
            setBatchData(oldBatchData)
        }
    }

    const caluculateReviewMarks = () => {
        const deadlinePercentage = reviewDataPercentage?.deadlinePercentage || 0
        const analysisPercentage = reviewDataPercentage?.analyticsPercentage || 0
        const databasePercentage = reviewDataPercentage?.databasePercentage || 0
        const uiPercentage = reviewDataPercentage?.uiPercentage || 0
        const serversidePercentage = reviewDataPercentage?.serversidePercentage || 0
        const deadline = reviewData?.deadline ? (reviewData.deadline * deadlinePercentage) / 100 : 0
        const analytics = reviewData?.analytics ? (reviewData.analytics * analysisPercentage) / 100 : 0
        const database = reviewData?.database ? (reviewData.database * databasePercentage) / 100 : 0
        const ui = reviewData?.ui ? (reviewData.ui * uiPercentage) / 100 : 0
        const serverside = reviewData?.serverside ? (reviewData.serverside * serversidePercentage) / 100 : 0
        return {
            marks: (deadline + analytics + database + serverside + ui),
            marksPercentage: (deadlinePercentage + analysisPercentage + databasePercentage + uiPercentage + serversidePercentage),
        }
    }

    const handleSubmit = (user, examCode) => {
        const ExamMarks = reviewData.onDeadline !== "" ? {
            ...Object.assign(reviewDataPercentage, reviewData), onDeadline: reviewData.onDeadline === "1" ? true : false, marks: caluculateReviewMarks()?.marks,
        } : {
            marks: user.marks,
            remark: user.remark
        };
        let typeOfExam = ExamType === "externalMeeting" ? "externalExam" : ExamType, examType = ExamType === "externalMeeting" ? "meeting" : 'practical';
        const data = {
            batchId: batchData.batchId,
            userId: user.userId,
            subCourceId: batchData.skillId,
            [ExamType]: {
                ...ExamMarks,
                reviewBy: currentUser.username,
                examId: examCode?.[user[typeOfExam][examType]?.length || 0]?.examId,
            },
        };
        if ((ExamMarks?.marks >= 0 && ExamMarks?.marks <= 100) && ExamMarks.remark) {
            if (window.confirm(`Are you sure you want to add this marks for Exam Code: ${examCode?.[user[typeOfExam][examType]?.length || 0]?.examId} and Employee ID: ${user.empId}`)) {
                CourseServices.postExamAnswer(localStorage.getItem("token"), data)
                    .then((res) => {
                        if (res.data.status) {
                            if (reviewData.onDeadline !== "") {
                                setPracticalExamData({ ...practicalExamData, reviewData: res.data.finalResult[typeOfExam][examType] })
                            }
                            setExamData(res.data.finalResult)
                            clearReviewData()
                        }
                    })
                    .catch((err) => {
                        handleCatchError(err)
                    });
            }
        } else {
            handleMessage("Marks values less than 100 and remark is required", false, true)
        }
    }

    const closeMcqModel = () => {
        setMcqsMarksModel(!openMcqsMarksModel);
    };
    const handleMcqUrl = (userData, examType) => {
        const findMcqExamId = userData?.[examType]?.['mcq']?.[userData[examType]['mcq']?.length - 1]?.examId?._id
        if (findMcqExamId && userData?.userId && examType) {
            return {
                status: true,
                url: `/correctanswer/${findMcqExamId}/${userData.userId}/${examType}`
            }
        } else {
            return {
                status: false,
                url: 'javascript:void("")'
            }
        }
    };

    const closeModal = () => {
        setOpenModal(!openModal);
        setPracticalExamId([]);
        setMarkRemarkValidation([]);
        setHandleEditId("");
        clearReviewData()
    };

    const handleStatus = (skillName) => {
        if (skillName) {
            if (skillName === "TrainingProject" || skillName === "LiveCodingTest") {
                return {
                    Name: "Project Review",
                    Status: false,
                };
            } else {
                return {
                    Name: "Pratical Exam List",
                    Status: true,
                };
            }
        } else {
            return {
                Name: "Exam",
                Status: true,
            };
        }
    };

    const handleEditAndClearFunctionality = (id, name) => {
        if (name === "edit") {
            let newArr = [...practicalExamId]; // copying the old datas array
            newArr.push(id);
            setPracticalExamId(newArr);
        } else {
            const index = practicalExamId.findIndex((item) => {
                return item === id;
            });
            let newArr = [...practicalExamId];
            newArr.splice(index, 1);
            setPracticalExamId(newArr);
        }
    };

    const handleDeleteData = (Id) => {
        const token = localStorage.getItem("token");
        const addMarkList = practicalExamData.reviewData?.filter(
            (reviewData) => reviewData._id !== Id
        );
        if (token) {
            if (
                window.confirm("Are you sure you want to delete this existing entry")
            ) {
                CourseServices.deletePracticalExamMark(
                    ExamType,
                    addMarkList,
                    Id,
                    token
                )
                    .then((res) => {
                        if (res.data) {
                            setConfirmbox(true);
                            setMsg({ message: res.data.msg, status: res.data.status });
                            if (res.data.status === true) {
                                setPracticalExamData({
                                    ...practicalExamData,
                                    reviewData: addMarkList,
                                });
                                setExamData(res.data.finalResult)
                                setPracticalExamId([]);
                                setMarkRemarkValidation([]);
                            }
                            setTimeout(() => setConfirmbox(false), 5000);
                        }
                    })
                    .catch((err) => {
                        handleCatchError(err)
                    });
            }
        } else {
            navigate("/login");
        }
    };

    const handleEditMark = async () => {
        const token = localStorage.getItem("token");
        let practicalReviewList = [];
        if (handleEditId && handleEditId !== "") {
            practicalReviewList = practicalExamData.reviewData.map((item1) => {
                if (item1._id === handleEditId) {
                    return {
                        ...item1,
                        ...Object.assign(reviewDataPercentage, reviewData),
                        onDeadline: reviewData.onDeadline === "1" ? true : false,
                        marks: caluculateReviewMarks()?.marks,
                    };
                } else {
                    return item1;
                }
            });
        } else {
            practicalReviewList = practicalExamData.reviewData;
        }
        const verifyError = await handleMarkAndRemark(practicalReviewList);
        if (token) {
            if (!markRemarkValidation.includes(true) && verifyError) {
                CourseServices.changePracticalExamMark(
                    ExamType,
                    practicalReviewList,
                    token
                )
                    .then((res) => {
                        if (res.data) {
                            setConfirmbox(true);
                            setMsg({ message: res.data.msg, status: res.data.status });
                            if (res.data.status === true) {
                                setPracticalExamId([]);
                                setMarkRemarkValidation([]);
                                setPracticalExamData({
                                    ...practicalExamData,
                                    reviewData: practicalReviewList,
                                });
                                setExamData(res.data.finalResult)
                                setHandleEditId("");
                                clearReviewData()
                            }
                            setTimeout(() => setConfirmbox(false), 3000);
                        }
                    })
                    .catch((err) => {
                        handleCatchError(err);
                    });
            } else {
                setConfirmbox(true);
                setMsg({
                    message: `Please verify your ${markRemarkValidation.includes(true) ? "marks and remark " : ""
                        } required field`,
                    status: false,
                });
                setMarkRemarkValidation([]);
                setTimeout(() => setConfirmbox(false), 5000);
            }
        } else {
            navigate("/login");
        }
    };

    const handleMarkAndRemark = (reviewData) => {
        let validValue = true;
        if (reviewData?.length > 0) {
            reviewData.forEach((element) => {
                if (element.marks === "" || element.remark === "") {
                    markRemarkValidation.push(true);
                    validValue = false;
                }
            });
            return validValue;
        } else {
            return validValue;
        }
    };

    const handleSubmitFunction = (e) => {
        e.preventDefault();
        if (handleEditId && handleEditId !== "") {
            handleEditMark();
        } else {
            handleSubmit(practicalExamData);
        }
    };

    const handleValidation = () => {
        const validatetion = {};
        for (const key in reviewDataPercentage) {
            const finalKeys = key == 'onDeadline' ? key : key.split(/(?=[A-Z])/)[0];
            if (caluculateReviewMarks()?.marks > 0 && caluculateReviewMarks()?.marksPercentage === 100) {
                validatetion[finalKeys] = false;
                if (typeof (reviewData[finalKeys]) === 'string') {
                    if (reviewData[finalKeys]) {
                        validatetion[finalKeys] = false;
                    } else {
                        validatetion[finalKeys] = true;
                    }
                } else {
                    if (reviewData[finalKeys] >= 0) {
                        validatetion[finalKeys] = false;
                    } else {
                        validatetion[finalKeys] = true;
                    }
                }
            } else {
                validatetion[finalKeys] = true;
            }
        }
        return Object.values(validatetion).includes(true) ? true : false;
    };

    const handleDeadlineStatus = (status) => {
        const data =
            status === true
                ? { label: "Yes", value: "1" }
                : status === false
                    ? { label: "No", value: "0" }
                    : { label: "No Found", value: "" };
        return data;
    };

    const handleTrainingProjectURL = (assignments) => {
        let frontEndUrl = ""
        if (assignments?.length > 0) {
            assignments.forEach((assignment) => {
                if (assignment?.sourcePath?.includes("FrontEnd")) {
                    frontEndUrl = assignment.siteUrl;
                }
            })
        } else {
            frontEndUrl = ""
        }
        return frontEndUrl;
    }

    const clearReviewData = () => {
        setReviewData({
            onDeadline: "",
            remark: "",
        });
        setReviewDataPercentage({
            deadlinePercentage: 10,
            analyticsPercentage: 30,
            databasePercentage: 20,
            uiPercentage: 20,
            serversidePercentage: 20,
            remark: "",
            onDeadline: ""

        })
    }

    const DateFormat = (dateValue) => {
        let date = new Date(dateValue);
        return `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
            }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
    };

    const handlePracticalMark = (e) => {
        const { name, value } = e.target
        const veriftyPercentage = name + 'Percentage'
        if (parseFloat(value) >= 0 && parseFloat(value) <= 100 && reviewDataPercentage[veriftyPercentage]) {
            setReviewData({ ...reviewData, [name]: parseFloat(value) })
        } else {
            setReviewData(reviewData => {
                const reviewDatacopy = { ...reviewData };
                delete reviewDatacopy[name];
                return reviewDatacopy;
            });
        }
    }

    const handlePracticalMarkPercentage = (e) => {
        const { name, value } = e.target
        if (parseFloat(value) >= 0 && parseFloat(value) <= 100) {
            setReviewDataPercentage({ ...reviewDataPercentage, [name]: parseFloat(value) })
        } else {
            const finalKey = name.split(/(?=[A-Z])/)[0];
            setReviewDataPercentage(reviewDataPercentage => {
                const copyReviewDataPercentage = { ...reviewDataPercentage };
                delete copyReviewDataPercentage[name];
                return copyReviewDataPercentage;
            });
            setReviewData(
                reviewData => {
                    const copyReviewData = { ...reviewData };
                    delete copyReviewData[finalKey];
                    return copyReviewData;
                }
            )
        }
    }

    const handlePracticalMarks = (reviewMarks, percentageData) => {
        setHandleEditId(reviewMarks._id);
        const editReviewData = {
            remark: reviewMarks.remark,
            onDeadline: handleDeadlineStatus(
                reviewMarks.onDeadline
            ).value
        }
        const editPercentageReviewData = {
            remark: "",
            onDeadline: ""
        }
        for (let key in percentageData) {

            const finalKey = key.split(/(?=[A-Z])/)[0];
            editPercentageReviewData[key] = percentageData[key] || 0;
            editReviewData[finalKey] = reviewMarks[finalKey]

        }
        setReviewData(editReviewData)
        setReviewDataPercentage(editPercentageReviewData)
    }

    const handleSourcePath = (path) => {
        if (path.includes('3.6.85.37')) {
            const splitPath = path.split('3.6.85.37')
            if (splitPath[1].includes('rx.json/')) {
                const splitJsonPath = splitPath[1].split('rx.json/')
                return `${splitPath[0]}10.10.10.43${splitJsonPath[0]}${splitJsonPath[1]}`
            } else {
                return `${splitPath[0]}10.10.10.43${splitPath[1]}`
            }
        }
        return path
    }

    const countAssignmentAndPractical = (data, name) => {
        const totalCount = data[name].reduce((count, item) => {
            return count += item.title?.trim() ? 1 : 0
        }, 0);
        return totalCount
    }

    return (
        <>
            {loader &&
                <Loader loaderStatus={loader} message="Loading ....." />
            }

            {confirmBox && <HandleMessage message={message} />}

            {batchData?.users?.length > 0 &&
                <>
                    <section className="batchinfo-module p-xl-5 p-lg-4 p-3">
                        <div className="container-fluid">
                            <div className="d-flex flex-wrap justify-content-between">
                                <h4 className="font-weight-600 me-3">Course: <span className="font-weight-400 h5">{SkillName} {ExamType}</span></h4>
                                <h4 className="font-weight-600">Batch Name: <span className="font-weight-400 h5">{batchData.batchName}</span></h4>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="accordion" id="accordionBatchInfo">
                                        {batchData?.users?.map((user, index) => {
                                            const createInternalUrl = handleMcqUrl(user, 'internalExam');
                                            const createExternalUrl = handleMcqUrl(user, 'externalExam');
                                            return (
                                                <div className="accordion-item mb-3">
                                                    <div className="d-flex bg-blue text-white px-3 py-2">
                                                        {user.username} - {user.empId}
                                                        <span className="ms-auto" data-bs-toggle="collapse" data-bs-target={`#collapse${user.empId}`} style={{ cursor: 'pointer' }}>
                                                            <i className="fas fa-chevron-down"></i>
                                                        </span>
                                                    </div>
                                                    <ul className="list-inline batchinfo-list d-flex justify-content-between align-items-center mb-0 px-3 py-2">
                                                        {user?.sonarInfo?.alert_status ? user.sonarInfo.alert_status === "ERROR"
                                                            ? (
                                                                <li className="list-inline-item text-danger">
                                                                    <i className="fas fa-thumbs-down p-0 me-2"></i>
                                                                    Fail
                                                                </li>
                                                            ) : (
                                                                <li className="list-inline-item text-success">
                                                                    <i className="fas fa-thumbs-up p-0 me-2"></i>
                                                                    Success
                                                                </li>
                                                            ) : <li className="list-inline-item text-danger">
                                                            <i className="fas fa-thumbs-down p-0 me-2"></i>
                                                            NA
                                                        </li>}
                                                        <li className="list-inline-item">
                                                            <i className="fa-solid fa-bug me-2"></i>
                                                            {user?.sonarInfo?.bugs ? user.sonarInfo.bugs : <span className="text-danger">NA</span>}
                                                        </li>
                                                        {ExamType === "internalExam" &&
                                                            <>
                                                                <li className="list-inline-item">
                                                                    <span className="me-2">A -</span>
                                                                    {user?.practicesAndAssignment?.assignments?.length !== 0 ? countAssignmentAndPractical(user?.practicesAndAssignment, "assignments") : <span className="text-danger">NA</span>}/
                                                                    {batchData?.assignment}
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <span className="me-2">p -</span>
                                                                    {user?.practicesAndAssignment?.practices?.length !== 0 ? countAssignmentAndPractical(user.practicesAndAssignment, "practices") : <span className="text-danger">NA</span>}/
                                                                    {batchData?.practices}
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <i className="far fa-file-excel me-2"></i>
                                                                    {user?.userAttednace}/{batchData.days}
                                                                </li>
                                                            </>
                                                        }
                                                        <li className="list-inline-item">
                                                            <i className="fas fa-clock me-2"></i>
                                                            <a to="#" onClick={() => { }}>
                                                                {user?.userMisReport?.PresentDays}/{user?.userMisReport?.TotalWorkingDays}</a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <img src="https://gdm-catalog-fmapi-prod.imgix.net/ProductLogo/9e85a16a-c862-4238-9ca5-f5dfa2df8a84.png" height='16px' className="me-2" />
                                                            {user?.userMisReport?.TotalProductiveTime}/{user?.userMisReport?.RequiredDeskTime}
                                                        </li>
                                                        {ExamType === "internalExam" ?
                                                            <>
                                                                <li className="list-inline-item">
                                                                    <span className="me-2">MCQ - I</span>
                                                                    <a href="#" onClick={() => {
                                                                        setMcqsMarksModel(
                                                                            !openMcqsMarksModel
                                                                        );
                                                                        setMcqsData({ mcqsDataMarks: user?.internalExam?.mcq ? user.internalExam.mcq : [], userId: user.userId, userName: user.userName, mcqsExamCode: user?.internalExamCode ? user.internalExamCode : [], examType: 'internalExam' })
                                                                    }}>{getInternalExamMark(user.internalExam, 'mcq').toFixed(2)}</a><Link to={createInternalUrl?.url} target={createInternalUrl?.status && '_blank'}><i className="fas fa-comment ms-2"
                                                                    ></i>
                                                                    </Link>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <i className="fas fa-laptop-code me-2"></i><a href="#" onClick={() => {
                                                                        setOpenModal(!openModal);
                                                                        setPracticalExamData({
                                                                            reviewData: user?.internalExam?.practical ? user?.internalExam?.practical : [],
                                                                            hostId: user?.hostId,
                                                                            userId: user?.userId,
                                                                            examType: 'Internal',
                                                                            userName: user?.username,
                                                                            buttonStatus: true
                                                                        })
                                                                    }}>{getInternalExamMark(user.internalExam, 'practical').toFixed(2)}</a>
                                                                    {handleStatus(SkillName)?.Status ? <Link to={batchData.batchName && findExamCode(user.internalExam, user.internalExamCode)?.examId !== 'Not Created Exam' && SkillName && user.userId && user.hostId ? `/ExamVideo/${batchData.batchName}/${SkillName}/${findExamCode(user.internalExam, user.internalExamCode)?.examId}/${user.hostId}/${user.userId}` : 'javascript:void("")'} target={batchData.batchName && findExamCode(user.internalExam, user.internalExamCode)?.examId !== 'Not Created Exam' && SkillName && user.userId && user.hostId && '_blank'}><i className="fas fa-play-circle ms-2"></i></Link>
                                                                        : <Link to={handleTrainingProjectURL(user.practicesAndAssignment.assignments) !== "" ? handleTrainingProjectURL(user.practicesAndAssignment.assignments) : 'javascript:void("")'} target={handleTrainingProjectURL(user.practicesAndAssignment.assignments) !== "" && '_blank'}><i className="fas fa-play-circle ms-2"></i></Link>
                                                                    }
                                                                </li>
                                                            </> :
                                                            <>
                                                                <li className="list-inline-item"><span className="me-2">MCQ - I</span>
                                                                    <a href="#" onClick={() => {
                                                                        setMcqsMarksModel(
                                                                            !openMcqsMarksModel
                                                                        );
                                                                        setMcqsData({ mcqsDataMarks: user?.internalExam?.mcq ? user.internalExam.mcq : [], userId: user.userId, userName: user.userName, mcqsExamCode: user?.internalExamCode ? user.internalExamCode : [], examType: 'internalExam' })
                                                                    }}>{getInternalExamMark(user.internalExam, 'mcq').toFixed(2)}</a><Link to={createInternalUrl?.url} target={createInternalUrl?.status && '_blank'}><i
                                                                        className="fas fa-comment ms-2"></i>
                                                                    </Link>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <i className="fas fa-laptop-code me-2"></i><a href="#" onClick={() => {
                                                                        setOpenModal(!openModal);
                                                                        setPracticalExamData({
                                                                            reviewData: user?.internalExam?.practical ? user?.internalExam?.practical : [],
                                                                            hostId: user?.hostId,
                                                                            userId: user?.userId,
                                                                            examType: 'Internal',
                                                                            userName: user?.username,
                                                                            buttonStatus: false,
                                                                        })
                                                                    }}>{getInternalExamMark(user.internalExam, 'practical').toFixed(2)}</a>
                                                                </li>
                                                                <li className="list-inline-item"><span className="me-2">MCQ - E </span>
                                                                    <a href="#" onClick={() => {
                                                                        setMcqsMarksModel(
                                                                            !openMcqsMarksModel
                                                                        );
                                                                        setMcqsData({ mcqsDataMarks: user?.externalExam?.mcq ? user.externalExam.mcq : [], userId: user.userId, userName: user.userName, mcqsExamCode: user?.externalExamCode ? user.externalExamCode : [], examType: 'externalExam' })
                                                                    }}>{getInternalExamMark(user.externalExam, 'mcq').toFixed(2)}</a><Link to={createExternalUrl?.url} target={createExternalUrl?.status && '_blank'}><i

                                                                        className="fas fa-comment ms-2"></i>
                                                                    </Link>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <i className="fas fa-laptop-code me-2"></i><a href="#" onClick={() => {
                                                                        setOpenModal(!openModal);
                                                                        setPracticalExamData({
                                                                            reviewData: user?.externalExam?.practical ? user?.externalExam?.practical : [],
                                                                            hostId: user?.hostId,
                                                                            userId: user?.userId,
                                                                            examType: 'External',
                                                                            userName: user?.username,
                                                                            buttonStatus: true,
                                                                        })
                                                                    }}>{getInternalExamMark(user.externalExam, 'practical').toFixed(2)}</a>
                                                                    {handleStatus(SkillName)?.Status ? <Link to={batchData.batchName && findExamCode(user.externalExam, user.externalExamCode)?.examId !== 'Not Created Exam' && SkillName && user.userId && user.hostId ? `/ExamVideo/${batchData.batchName}/${SkillName}/${findExamCode(user.externalExam, user.externalExamCode)?.examId}/${user.hostId}/${user.userId}` : 'javascript:void("")'} target={batchData.batchName && findExamCode(user.externalExam, user.externalExamCode)?.examId !== 'Not Created Exam' && SkillName && user.userId && user.hostId && '_blank'}><i className="fas fa-play-circle ms-2"></i></Link>
                                                                        : <Link to={handleTrainingProjectURL(user.practicesAndAssignment.assignments) !== "" ? handleTrainingProjectURL(user.practicesAndAssignment.assignments) : 'javascript:void("")'} target={handleTrainingProjectURL(user.practicesAndAssignment.assignments) !== "" && '_blank'}><i className="fas fa-play-circle ms-2"></i></Link>
                                                                    }
                                                                </li>
                                                                <li className="list-inline-item"><span className="me-2">WA </span>
                                                                    {Math.round(user?.average || 0)}
                                                                </li>
                                                                <li className="list-inline-item"><span className="me-2">EMTG </span>
                                                                    <a href="#" onClick={() => {
                                                                        setOpenModal(!openModal);
                                                                        setPracticalExamData({
                                                                            reviewData: user?.externalExam?.meeting ? user?.externalExam?.meeting : [],
                                                                            hostId: user?.hostId,
                                                                            userId: user?.userId,
                                                                            examType: 'External',
                                                                            userName: user?.username,
                                                                            buttonStatus: true,
                                                                        })
                                                                    }}>{getInternalExamMark(user.externalExam, 'meeting').toFixed(2)}</a>
                                                                </li>
                                                            </>
                                                        }
                                                        {ExamType === "internalExam" ?
                                                            <>
                                                                {findExamCode(user.internalExam, user.internalExamCode)?.status ?
                                                                    <>
                                                                        <li className="list-inline-item me-3">
                                                                            <input type="number" class="form-control w-90px" name="mark" onChange={(e) => handleChange(e, user.userId)} placeholder="Marks" />
                                                                        </li>
                                                                        <li className="list-inline-item me-3">
                                                                            <textarea className="form-control" rows="1" placeholder="Remarks" name="remark" onChange={(e) => handleChange(e, user.userId)} style={{ width: '300px' }} />
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <button class="btn bg-blue text-white w-100 save_btn" disabled={user.marks && user.remark ? false : true} onClick={() => handleSubmit(user, user.internalExamCode.filter((exams) => exams?.examType === 'Practical'))}>Save</button>
                                                                        </li>
                                                                    </> : <li className="list-inline-item text-primary">{findExamCode(user.internalExam, user.internalExamCode)?.examId}</li>
                                                                }
                                                            </> :
                                                            <>
                                                            {findExamCode(user.externalExam, user.externalExamCode)?.status ?
                                                                <>
                                                                    <li className="list-inline-item me-3 d-flex">
                                                                        {
                                                                            ExamType === "externalMeeting" &&
                                                                            (
                                                                            <div className="my-auto user-select-none">
                                                                                <input 
                                                                                type="checkbox" 
                                                                                className="form-check-input" 
                                                                                name="checkbox"
                                                                                checked={user.IsExternalMarksAsInternal}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, user.userId)
                                                                                }} 
                                                                                id={"checkbox"+index+1} 
                                                                                />
                                                                                <label htmlFor={"checkbox"+index+1} className="align-center ms-2 me-3">Aligned with WA</label>
                                                                            </div>
                                                                            )
                                                                                                                                                            
                                                                        }
                                                                        <input type="number" class="form-control w-90px" value={user.marks} disabled={user.IsExternalMarksAsInternal} name="mark" onChange={(e) => handleChange(e, user.userId)} placeholder="Marks" />

                                                                    </li>
                                                                    <li className="list-inline-item me-3">
                                                                        <textarea className="form-control" rows="1" placeholder="Remarks" name="remark" onChange={(e) => handleChange(e, user.userId)} style={{ width: '300px' }} />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                    {
                                                                        ExamType === "externalMeeting" ?
                                                                        <button class="btn bg-blue text-white w-100 save_btn" disabled={user.marks && user.remark ? false : true} onClick={() => handleSubmit(user, user.externalExamCode.filter((exams) => exams?.examType === 'Meeting'))}>Save</button>
                                                                        :
                                                                        <button class="btn bg-blue text-white w-100 save_btn" disabled={user.marks && user.remark ? false : true} onClick={() => handleSubmit(user, user.externalExamCode.filter((exams) => exams?.examType === 'Practical'))}>Save</button>
                                                                    }
                                                                    </li>
                                                                </> : <li className="list-inline-item text-primary">{findExamCode(user.externalExam, user.externalExamCode)?.examId}</li>
                                                            }
                                                            </>
                                                        }
                                                    </ul>
                                                    <div id={`collapse${user.empId}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body px-3 py-2 border-top">
                                                            <ul className="nav nav-tabs">
                                                                <li className="nav-item">
                                                                    <a className="nav-link px-4 py-2 active" data-bs-toggle="tab" href={`#sonar${user.empId}`}>Sonar Status</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link px-4 py-2" data-bs-toggle="tab" href={`#attendance${user.empId}`}>Attendance Status</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link px-4 py-2" data-bs-toggle="tab" href={`#assignments${user.empId}`}>Total Assignments - ({user?.practicesAndAssignment?.assignments?.length || 0})</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link px-4 py-2" data-bs-toggle="tab" href={`#practices${user.empId}`}>Total Practices - ({user?.practicesAndAssignment?.practices?.length || 0})</a>
                                                                </li>
                                                            </ul>
                                                            <div className="tab-content">
                                                                <div className="tab-pane active" id={`sonar${user.empId}`}>
                                                                    <table className="table my-2">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="fw-bold">Alert Status :</span> <span className="font-weight-400">{user?.sonarInfo?.alert_status ? user.sonarInfo.alert_status : "NA"}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="fw-bold">Bugs :</span> <span className="font-weight-400">{user?.sonarInfo?.bugs ? user.sonarInfo.bugs : "NA"}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="fw-bold">Code Smells :</span> <span className="font-weight-400">{user?.sonarInfo?.code_smell ? user.sonarInfo.code_smell : "NA"}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="fw-bold">Duplicated Lines Density :</span> <span className="font-weight-400">{user?.sonarInfo?.duplicated_lines_density ? parseInt(user.sonarInfo.duplicated_lines_density).toFixed(2) : "NA"}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="tab-pane" id={`attendance${user.empId}`}>
                                                                    <table className="table my-2">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="fw-bold">Joining Date :</span> <span className="font-weight-400">{DateFormat(user?.userMisReport?.JoinDate)}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="fw-bold">Present Days :</span> <span className="font-weight-400">{user?.userMisReport?.PresentDays}/{user?.userMisReport?.TotalWorkingDays}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="fw-bold">Leave Days :</span> <span className="font-weight-400">{user?.userMisReport?.LeaveDays}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="fw-bold">Productive Time :</span> <span className="font-weight-400">{user?.userMisReport?.TotalProductiveTime}/{user?.userMisReport?.RequiredDeskTime}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="row">
                                                                        <div className="col-md-2 fw-bold ms-2">Leaves details :</div>
                                                                        <div className="col-md-9">
                                                                            {
                                                                                user?.userMisReport?.LeaveDetails?.length > 0 ?
                                                                                    <table className="table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Status</th>
                                                                                                <th>Type</th>
                                                                                                <th>Days</th>
                                                                                                <th>From Date</th>
                                                                                                <th>To Date</th>
                                                                                                <th>Reason</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                user.userMisReport.LeaveDetails.map((leaveDetails, index) => {
                                                                                                    return <tr keys={index}>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td>{leaveDetails.LeaveStatus}</td>
                                                                                                        <td>{leaveDetails.LeaveType}</td>
                                                                                                        <td>{leaveDetails.Days}</td>
                                                                                                        <td>{leaveDetails.FromDate}</td>
                                                                                                        <td>{leaveDetails.ToDate}</td>
                                                                                                        <td>{leaveDetails.Reason}</td>
                                                                                                    </tr>
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                    : <span className="text-danger">No Leaves Details Available</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="fw-bold ms-2">Leaves details :</div> */}

                                                                </div>
                                                                <div className="tab-pane fade" id={`assignments${user.empId}`}>
                                                                    {user?.practicesAndAssignment?.assignments?.length > 0 ?
                                                                        <>
                                                                            {
                                                                                user.practicesAndAssignment.assignments.map((assignment, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                assignment.title &&
                                                                                                <table className="table caption-top my-2" key={index}>
                                                                                                    <caption className="bg-blue px-2 py-1 text-white">Assignment {index + 1}</caption>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Title : </span><span className="font-weight-400">{assignment?.title ? assignment.title : "NA"}</span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Info:</span><Link to={assignment?.analysis?.info ? assignment.analysis.info : 'javascript:void("")'} target={assignment?.analysis?.info && "_blank"}><i className="fas fa-info ms-2"></i></Link>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Flowchart :</span><Link to={assignment?.analysis?.flowchart ? assignment.analysis.flowchart : 'javascript:void("")'} target={assignment?.analysis?.flowchart && "_blank"}><i className="fa-solid fa-chart-line ms-2"></i></Link>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">siteUrl : </span><Link to={assignment?.siteUrl ? assignment.siteUrl : 'javascript:void("")'} target={assignment?.siteUrl && "_blank"}><i className="fa-solid fa-link ms-2"></i></Link>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Source Path : </span><Link to={assignment?.sourcePath ? handleSourcePath(assignment.sourcePath) : 'javascript:void("")'} target={assignment?.sourcePath && "_blank"}><i className="fa-solid fa-road ms-2"></i></Link>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }</>
                                                                        : <span className="text-danget">No assignment avalible</span>}
                                                                </div>
                                                                <div className="tab-pane fade" id={`practices${user.empId}`}>
                                                                    {user?.practicesAndAssignment?.practices?.length > 0 ?
                                                                        <>
                                                                            {
                                                                                user.practicesAndAssignment.practices.map((practice, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                practice.title &&
                                                                                                <table className="table caption-top my-2" key={index}>
                                                                                                    <caption className="bg-blue px-2 py-1 text-white">Assignment {index + 1}</caption>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Title :</span><span className="font-weight-400">{practice?.title ? practice.title : "NA"}</span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Info:</span><Link to={practice?.analysis?.info ? practice.analysis.info : 'javascript:void("")'} target={practice?.analysis?.info && "_blank"}><i className="fas fa-info ms-2"></i></Link>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Flowchart :</span><Link to={practice?.analysis?.flowchart ? practice.analysis.flowchart : 'javascript:void("")'} target={practice?.analysis?.flowchart && "_blank"}><i className="fa-solid fa-chart-line ms-2"></i></Link>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">siteUrl :</span><Link to={practice?.siteUrl ? practice.siteUrl : ""} target={practice?.siteUrl && "_blank"}><i className="fa-solid fa-link ms-2"></i></Link>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="fw-bold">Source Path :</span><Link to={practice?.sourcePath ? handleSourcePath(practice.sourcePath) : 'javascript:void("")'} target={practice?.sourcePath && "_blank"}><i className="fa-solid fa-road ms-2"></i></Link>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }</>
                                                                        : <span className="text-danget">No practices avalible</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div>
                        <Modal
                            show={openMcqsMarksModel}
                            onHide={closeMcqModel}
                            class="mt-5"
                            size="xl"
                            aria-labelledby="ModalHeader"
                        >
                            <Modal.Header>
                                <Modal.Title ><div className="fw-bold">Exam Data</div>
                                    <div className="d-flex">
                                        <div><span className="text-primary">BatchName</span> : {batchData.batchName}</div>
                                        <div className="mx-2"><span className="text-primary">SkillName</span> : {SkillName}</div>
                                        <div><span className="text-primary">UserName</span> : {mcqsData?.userName}</div>
                                        <div className="mx-2"><span className="text-primary">ExamType</span> : {mcqsData?.examType}</div>
                                    </div></Modal.Title>
                                <button
                                    onClick={() => {
                                        setMcqsMarksModel(!openMcqsMarksModel);
                                        setMcqsData([]);
                                        setPracticalExamId([]);
                                    }}
                                >
                                    <FaTimes />
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                {confirmbox && (
                                    <div className="formloade">
                                        <div className="row text-center">
                                            <div className="col-6 w-100 confirmbox">
                                                <div
                                                    className={`alert ${msg.status ? "alert-success" : "alert-danger"
                                                        }`}
                                                >
                                                    {msg.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colspan="1">
                                                #
                                            </th>
                                            <th scope="col" colspan="2">
                                                Exam Code
                                            </th>
                                            <th scope="col" colspan="2">
                                                Mark(%)
                                            </th>
                                            <th scope="col" colspan="2">
                                                Answer
                                            </th>
                                            <th scope="col" colspan="2">
                                                Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBody">
                                        {mcqsData?.mcqsDataMarks?.length > 0
                                            &&
                                            mcqsData.mcqsDataMarks.map((item, index) => {
                                                const date = new Date(item.reviewDate);
                                                let examdate = `${date.getDate()}/${date.getMonth() + 1
                                                    }/${date.getFullYear()}`;
                                                return (
                                                    <tr>
                                                        <td scope="row" colspan="1">
                                                            {index + 1}
                                                        </td>
                                                        <td colspan="2">{item?.examId?.examId}</td>
                                                        <td colspan="2">{item && item.marks.toFixed(2)}</td>
                                                        <td colspan="2">
                                                            <Link to={`/correctanswer/${item.examId._id}/${mcqsData?.userId}/${mcqsData?.examType}`} target="_blank"><BiCommentDetail
                                                            ></BiCommentDetail></Link>
                                                        </td>
                                                        <td colspan="2">{examdate}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    onClick={() => {
                                        setMcqsMarksModel(!openMcqsMarksModel);
                                    }}
                                >
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        <Modal
                            show={openModal}
                            onHide={closeModal}
                            class="mt-5"
                            size="xl"
                            aria-labelledby="ModalHeader"
                        >
                            <Modal.Header>
                                <Modal.Title class="fw-bold">
                                    <div>{handleStatus(SkillName)?.Name}</div>
                                    <div className="d-flex">
                                        <div><span className="text-primary">BatchName</span> : {batchData.batchName}</div>
                                        <div className="mx-2"><span className="text-primary">SkillName</span> : {SkillName}</div>
                                        <div><span className="text-primary">UserName</span> : {practicalExamData?.userName}</div>
                                        <div className="mx-2"><span className="text-primary">ExamType</span> : {practicalExamData?.examType}</div>
                                    </div>
                                </Modal.Title>
                                <button
                                    onClick={(e) => {
                                        setOpenModal(!openModal);
                                        setPracticalExamId([]);
                                        setMarkRemarkValidation([]);
                                        setHandleEditId("");
                                        clearReviewData()
                                    }}
                                >
                                    <FaTimes />
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                {confirmbox && (
                                    <div className="formloade">
                                        <div className="row text-center">
                                            <div className="col-6 w-100 confirmbox">
                                                <div
                                                    className={`alert ${msg.status ? "alert-success" : "alert-danger"
                                                        }`}
                                                >
                                                    {msg.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {handleStatus(SkillName)?.Status ? (
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    #
                                                </th>
                                                <th scope="col">
                                                    Marks(%)
                                                </th>
                                                <th scope="col">
                                                    Exam Code
                                                </th>
                                                {practicalExamData.reviewData &&
                                                    practicalExamData.reviewData.length > 0 &&
                                                    practicalExamData.reviewData[0].remark && (
                                                        <th scope="col">
                                                            Remark
                                                        </th>
                                                    )}
                                                {batchData.batchName &&
                                                    ExamType !=="externalMeeting" &&
                                                    SkillName &&
                                                    practicalExamData.reviewData &&
                                                    practicalExamData.reviewData.length > 0 &&
                                                    practicalExamData.hostId &&
                                                    practicalExamData.userId && (
                                                        <th scope="col">
                                                            Video Link
                                                        </th>
                                                    )}
                                                {practicalExamData.reviewData &&
                                                    practicalExamData.reviewData.length > 0 &&
                                                    practicalExamData.reviewData[0].reviewBy && (
                                                        <th scope="col">
                                                            Review By
                                                        </th>
                                                    )}
                                                <th scope="col">
                                                    Date
                                                </th>
                                                {currentUser.role === "Admin" && practicalExamData.buttonStatus && (
                                                    <th scope="col">
                                                        Action
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody id="tableBody">
                                            {practicalExamData.reviewData &&
                                                practicalExamData.reviewData.length > 0 &&
                                                practicalExamData.reviewData.map((item, index) => {
                                                    const date = new Date(item.reviewDate);
                                                    let temp = `${date.getDate()}/${date.getMonth() + 1
                                                        }/${date.getFullYear()}`;
                                                    return (
                                                        <tr>
                                                            <td scope="row">
                                                                <strong>{index + 1}</strong>
                                                            </td>
                                                            {!practicalExamId.includes(item._id) ? (
                                                                <td>{item && item.marks}</td>
                                                            ) : (
                                                                <td>
                                                                    <input
                                                                        value={item.marks}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            if (
                                                                                e.target.value >= 0 &&
                                                                                e.target.value <= 100
                                                                            ) {
                                                                                const newArr =
                                                                                    practicalExamData.reviewData.map(
                                                                                        (item1) => {
                                                                                            if (item1._id === item._id) {
                                                                                                return {
                                                                                                    ...item1,
                                                                                                    marks: parseInt(e.target.value),
                                                                                                };
                                                                                            } else {
                                                                                                return item1;
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                setPracticalExamData({
                                                                                    ...practicalExamData,
                                                                                    reviewData: newArr,
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                            )}
                                                            <td className="w-50">
                                                                    {item.examId}
                                                            </td>
                                                            {!practicalExamId.includes(item._id) ? (
                                                                <td className="w-50">
                                                                    {item.remark}
                                                                </td>
                                                            ) : (
                                                                <td className="w-50">
                                                                    <textarea
                                                                        type="textbox"
                                                                        value={item.remark}
                                                                        rows="3"
                                                                        cols="40"
                                                                        onChange={(e) => {
                                                                            const newArr =
                                                                                practicalExamData.reviewData.map(
                                                                                    (item1) => {
                                                                                        if (item1._id === item._id) {
                                                                                            return {
                                                                                                ...item1,
                                                                                                remark: e.target.value,
                                                                                            };
                                                                                        } else {
                                                                                            return item1;
                                                                                        }
                                                                                    }
                                                                                );
                                                                            setPracticalExamData({
                                                                                ...practicalExamData,
                                                                                reviewData: newArr,
                                                                            });
                                                                        }}
                                                                    />
                                                                </td>
                                                            )}
                                                            {ExamType !=="externalMeeting" &&
                                                                (batchData.batchName &&
                                                                SkillName &&
                                                                item?.examId &&
                                                                practicalExamData.hostId &&
                                                                practicalExamData.userId ? (
                                                                <td>
                                                                    <Link
                                                                        target="_blank"
                                                                        to={`/ExamVideo/${batchData.batchName}/${SkillName}/${item.examId
                                                                            }/${practicalExamData.hostId}/${practicalExamData.userId
                                                                            }`}
                                                                    >
                                                                        <i class="fa-solid fa-play-circle fs-5"></i>
                                                                    </Link>
                                                                </td>
                                                            ) : (
                                                                <td class="text-danger">
                                                                    No Video Found
                                                                </td>
                                                            ))}
                                                            {item.reviewBy && (
                                                                <td>{item && item.reviewBy}</td>
                                                            )}
                                                            <td>{temp && temp}</td>
                                                            {currentUser.role === "Admin" && practicalExamData.buttonStatus &&
                                                                (!practicalExamId.includes(item._id) ? (
                                                                    <td>
                                                                        <button
                                                                            class="btn btn-primary px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                                                                            onClick={() => {
                                                                                handleEditAndClearFunctionality(
                                                                                    item._id,
                                                                                    "edit"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <FaPen className="color-white text-white" />
                                                                        </button>
                                                                        {
                                                                            ExamType !=="externalMeeting" &&(
                                                                                <button
                                                                                    class="btn btn-danger px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                                                                                    onClick={() => {
                                                                                        handleDeleteData(item._id);
                                                                                    }}
                                                                                >
                                                                                    <FaTrash className="color-white text-white" />
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </td>
                                                                ) : (
                                                                    <td>
                                                                        <button
                                                                            class="btn btn-dark px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                                                                            onClick={() => {
                                                                                setMarkRemarkValidation([]);
                                                                                handleEditAndClearFunctionality(
                                                                                    item._id,
                                                                                    "clear"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <FaTimes className="color-white text-white" />
                                                                        </button>
                                                                    </td>
                                                                ))}
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <>
                                        <div>
                                            {/* <div className="text-danger h4">
                                                Note :{" "}
                                                <span className="text-primary">
                                                    Enter all below details on base of 100. Showed Brackets
                                                    marks are weightage of that particular field{" "}
                                                </span>
                                            </div> */}
                                            <form
                                                className="row"
                                                onSubmit={(e) => handleSubmitFunction(e)}
                                            >
                                                <label className="col col-4 my-1" for="onDeadline">
                                                    {SkillName === "TrainingProject"
                                                        ? "Can communicate?"
                                                        : "Completed on Deadline?"}<span className="text-danger mx-1">*</span>
                                                </label>
                                                <div className="col col-2 my-1">
                                                    <select
                                                        className="form-select"
                                                        id="onDeadline"
                                                        name="onDeadline"
                                                        aria-label="Default select example"
                                                        onChange={(e) => {
                                                            setReviewData({
                                                                ...reviewData,
                                                                onDeadline: e.target.value,
                                                            })
                                                        }
                                                        }
                                                        value={reviewData.onDeadline}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                                <label className="col col-2 my-1" for="remarks">
                                                    Remarks<span className="text-danger mx-1">*</span>
                                                </label>
                                                <div className="col col-4 my-1">
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            id="remarks"
                                                            className="form-control"
                                                            placeholder="Remarks"
                                                            value={reviewData.remark}
                                                            onChange={(e) => {
                                                                setReviewData({
                                                                    ...reviewData,
                                                                    remark: e.target.value,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <label className="col col-2 my-1" for="deadline">
                                                    {SkillName === "TrainingProject"
                                                        ? "Communication"
                                                        : "Deadline"}
                                                </label>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            name="deadlinePercentage"
                                                            className="form-control"
                                                            placeholder="Percentage"
                                                            value={reviewDataPercentage.deadlinePercentage}
                                                            onChange={(e) => {
                                                                handlePracticalMarkPercentage(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            name="deadline"
                                                            className="form-control"
                                                            placeholder="Marks"
                                                            disabled={reviewDataPercentage?.deadlinePercentage ? false : true}
                                                            value={reviewData?.deadline ? reviewData.deadline : ""}
                                                            onChange={(e) => {
                                                                handlePracticalMark(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <label className="col col-2 my-1">
                                                    Analytics
                                                </label>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            id="analytics"
                                                            name="analyticsPercentage"
                                                            className="form-control"
                                                            placeholder="Percentage"
                                                            value={reviewDataPercentage.analyticsPercentage}
                                                            onChange={(e) => {
                                                                handlePracticalMarkPercentage(e)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            id="analytics"
                                                            name="analytics"
                                                            className="form-control"
                                                            disabled={reviewDataPercentage?.analyticsPercentage ? false : true}
                                                            value={reviewData?.analytics ? reviewData.analytics : ""}
                                                            placeholder="Marks"
                                                            onChange={(e) => {
                                                                handlePracticalMark(e)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <label className="col col-2 my-1">
                                                    Database
                                                </label>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            name="databasePercentage"
                                                            className="form-control"
                                                            placeholder="Percentage"
                                                            value={reviewDataPercentage.databasePercentage}
                                                            onChange={(e) => {
                                                                handlePracticalMarkPercentage(e)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            name="database"
                                                            className="form-control"
                                                            placeholder="Marks"
                                                            disabled={reviewDataPercentage?.databasePercentage ? false : true}
                                                            value={reviewData?.database ? reviewData.database : ''}
                                                            onChange={(e) => {
                                                                handlePracticalMark(e)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <label className="col col-2 my-1">
                                                    UI
                                                </label>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            name="uiPercentage"
                                                            className="form-control"
                                                            placeholder="Percentage"
                                                            value={reviewDataPercentage.uiPercentage}
                                                            onChange={(e) => {
                                                                handlePracticalMarkPercentage(e)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            id="ui"
                                                            name="ui"
                                                            className="form-control"
                                                            placeholder="Marks"
                                                            disabled={reviewDataPercentage?.uiPercentage ? false : true}
                                                            value={reviewData?.ui ? reviewData.ui : ''}
                                                            onChange={(e) => {
                                                                handlePracticalMark(e)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <label className="col col-2 my-1">
                                                    ServerSide
                                                </label>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            name="serversidePercentage"
                                                            className="form-control"
                                                            placeholder="Percentage"
                                                            value={reviewDataPercentage.serversidePercentage}
                                                            onChange={(e) => {
                                                                handlePracticalMarkPercentage(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-2 my-1">
                                                    <div className="">
                                                        <input
                                                            type="number"
                                                            id="serverside"
                                                            name="serverside"
                                                            className="form-control"
                                                            placeholder="Marks"
                                                            disabled={reviewDataPercentage?.serversidePercentage ? false : true}
                                                            value={reviewData?.serverside ? reviewData.serverside : ''}
                                                            onChange={(e) => {
                                                                handlePracticalMark(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <label className="col col-2 py-2" for="marks">
                                                    marks - <span className={caluculateReviewMarks().marksPercentage === 100 ? 'text-success' : 'text-danger'}>({caluculateReviewMarks().marksPercentage})</span>
                                                </label>
                                                <div className="col col-1 py-2">
                                                    {caluculateReviewMarks()?.marks.toFixed(2)}
                                                </div>
                                                <div className="col col-3 my-1">
                                                    <div className=" float-end">
                                                        <button
                                                            className="btn btn-primary px-3 mx-3"
                                                            disabled={handleValidation()}
                                                            type="submit"
                                                        >
                                                            {handleEditId ? "Update" : "Save"}
                                                        </button>
                                                        <button
                                                            className="btn btn-secondary"
                                                            type="button"
                                                            onClick={() => {
                                                                setHandleEditId("");
                                                                clearReviewData()
                                                            }}
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="mt-5">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" colspan="1">
                                                            {SkillName === "TrainingProject"
                                                                ? "Can communicate?"
                                                                : "Completed on Deadline?"}
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            {SkillName === "TrainingProject"
                                                                ? "Communication"
                                                                : "Deadline"}
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            Analytics
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            Database
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            Ui
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            Serverside
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            Remark
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            Marks(%)
                                                        </th>
                                                        <th scope="col" colspan="1">
                                                            Review By
                                                        </th>
                                                        {currentUser.role === "Admin" && practicalExamData.buttonStatus && (
                                                            <th scope="col" colspan="2">
                                                                Action
                                                            </th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {practicalExamData?.reviewData?.length > 0 &&
                                                        practicalExamData.reviewData.map((reviews) => {
                                                            var startTime = DateFormat("2023-09-19")
                                                            var endTime = DateFormat(reviews.reviewDate);
                                                            if (endTime < startTime) {
                                                                var percentageData = {
                                                                    'deadlinePercentage': 10,
                                                                    'uiPercentage': 20,
                                                                    'databasePercentage': 20,
                                                                    'analyticsPercentage': 30,
                                                                    'serversidePercentage': 20,
                                                                }
                                                            } else {
                                                                var percentageData = {
                                                                    'deadlinePercentage': reviews?.deadlinePercentage && reviews.deadlinePercentage,
                                                                    'uiPercentage': reviews?.uiPercentage && reviews.uiPercentage,
                                                                    'databasePercentage': reviews?.databasePercentage && reviews.databasePercentage,
                                                                    'analyticsPercentage': reviews?.analyticsPercentage && reviews.analyticsPercentage,
                                                                    'serversidePercentage': reviews?.serversidePercentage && reviews.serversidePercentage,
                                                                }
                                                            }
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {handleDeadlineStatus(reviews.onDeadline).label}
                                                                    </td>
                                                                    <td>{percentageData.deadlinePercentage > 0 && <span>{reviews.deadline}({percentageData.deadlinePercentage}%)</span>}</td>
                                                                    <td>{percentageData.analyticsPercentage > 0 && <span>{reviews.analytics}({percentageData.analyticsPercentage}%)</span>}</td>
                                                                    <td>{percentageData.databasePercentage > 0 && <span>{reviews.database}({percentageData.databasePercentage}%)</span>}</td>
                                                                    <td>{percentageData.uiPercentage > 0 && <span>{reviews.ui}({percentageData.uiPercentage}%)</span>}</td>
                                                                    <td>{percentageData.serversidePercentage > 0 && <span>{reviews.serverside}({percentageData.serversidePercentage}%)</span>}</td>
                                                                    <td>{reviews.remark}</td>
                                                                    <td>{reviews.marks.toFixed(2)}</td>
                                                                    <td>{reviews.reviewBy}</td>
                                                                    {currentUser.role === "Admin" && practicalExamData.buttonStatus && <td colspan="2">
                                                                        <button
                                                                            class="btn btn-primary px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                                                                            onClick={() => {
                                                                                handlePracticalMarks(reviews, percentageData)
                                                                            }}
                                                                        >
                                                                            <FaPen className="color-white text-white" />
                                                                        </button>
                                                                        <button
                                                                            class="btn btn-danger px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                                                                            onClick={() => {
                                                                                handleDeleteData(reviews._id);
                                                                            }}
                                                                        >
                                                                            <FaTrash className="color-white text-white" />
                                                                        </button>
                                                                    </td>}
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                {practicalExamId && practicalExamId.length > 0 && (
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        onClick={() => handleEditMark()}
                                    >
                                        Save
                                    </button>
                                )}
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    onClick={() => {
                                        setMarkRemarkValidation([]);
                                        setPracticalExamId([]);
                                        setOpenModal(!openModal);
                                        setHandleEditId("");
                                        clearReviewData()
                                    }}
                                >
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            }
        </>
    )
}